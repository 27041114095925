export enum QuadSizeType {
  tiny = "tiny",
  small = "small",
  medium = "medium",
  big = "big",
}

export enum OrientationType {
  horizontal = "horizontal",
  vertical = "vertical",
}

/**
 * @deprecated
 */
export enum TextType {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  h6 = "h6",
  subhead1 = "subhead1",
  subhead2 = "subhead2",
  body1 = "body1",
  body2 = "body2",
  caption1 = "caption1",
  caption2 = "caption2",
  caption3 = "caption3",
  // deprecated
  title = "title",
  subtitle = "subtitle",
  inherit = "inherit",
}

/**
 * @deprecated
 */
export enum TextWeightType {
  bold = "bold",
  normal = "normal",
}

/**
 * @deprecated
 */
export enum TextColorType {
  primary = "primary",
  dark = "dark",
  light = "light",
  gray = "gray",
  red = "red",
  darkBlue = "darkBlue",
  mediumDarkBlue = "mediumDarkBlue",
  lightGreen = "lightGreen",
  lightGray = "lightGray",
  darkGray = "darkGray",
  inherit = "inherit",
}
export enum KpiOverviewType {
  COST = "Cost",
  IMPRESSIONS = "Impressions",
  CPM = "Cpm",
  CLICKS = "Clicks",
  CTR = "Ctr",
  CONVRATE = "ConversionRate",
  CONV = "Conversions",
  AOV = "AOV",
  ACPC = "AverageCpc",
  COSTCONV = "CostConv",
  ALLCONV = "AllConversions",
  HOLDPLAY = "Holdplay",

  REVENUE = "Revenue",
  ROAS = "Roas",
  SEARCHIMPRSHARE = "SearchImpressionShare",
  THUMBSTOP = "Thumbstop",
}

export const KPI_OVERVIEW_TYPE_DESCRIPTION = Object.freeze<{
  [key in string]: string
}>({
  [KpiOverviewType.COST]: "Cost used to date (in USD)",
  [KpiOverviewType.IMPRESSIONS]: "Number of times the ad was shown",
  [KpiOverviewType.CPM]: "Cost per 1000 impressions (in USD)",
  [KpiOverviewType.CLICKS]: "Number of simple clicks of an add",
  [KpiOverviewType.ACPC]: "Cost per click (in USD)",
  [KpiOverviewType.CTR]:
    "A ratio showing how often (in %) people who see an ad listing end up clicking it",
  [KpiOverviewType.CONV]:
    "The total number of main conversion events (i.e. Transactions, Sign Up, or Lead etc) that occured after user interacted with an ad",
  [KpiOverviewType.COSTCONV]:
    "The average amount that has been charged for a conversion from an ad",
  [KpiOverviewType.CONVRATE]:
    "The average number of conversions per ad interaction (in %)",
  [KpiOverviewType.AOV]:
    "Average Order Value, the average dollar amount spent each time by placing an ad",
  [KpiOverviewType.REVENUE]: "Revenue",
  [KpiOverviewType.ROAS]:
    "Amount of revenue your business earns for each dollar it spends on advertisin",
  [KpiOverviewType.ALLCONV]:
    "The total number of all conversion actions that were taken",
  [KpiOverviewType.HOLDPLAY]:
    "The number of times users held to play video content",
  [KpiOverviewType.SEARCHIMPRSHARE]:
    "The percentage of impressions you received divided by the estimated number of impressions you were eligible to receive.",
  [KpiOverviewType.THUMBSTOP]:
    "The number of times users stopped scrolling to view your content.",
})

export type TypographyVariantType = keyof typeof TextType
export type TypographyColorType = keyof typeof TextColorType
