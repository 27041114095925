import React from "react"
import { MetricDescription, MetricsTrendType } from "@framework/types/metrics"
import clsx from "clsx"

import Typography from "@components/ui/Typography/Typography"
import { ViewCellProps } from "../types"
import MetricValueChip from "../../MetricValueChip"

import styles from "./TableCell.module.scss"

export interface MetricCellProps extends ViewCellProps<any> {
  getMetric: (name: string) => MetricDescription
  metricsTrend: MetricsTrendType
}

export const MetricCell: React.FC<MetricCellProps> = React.memo(
  ({ name, data, getMetric, metricsTrend }) => {
    const value = data?.[name]
    const metric = getMetric(name)
    let diffFromAverageValue: string = ""
    const isActive = Boolean(metricsTrend && metricsTrend[metric.name])
    if (metricsTrend && metricsTrend[metric.name] && value?.Value) {
      const { min } = metricsTrend[metric.name]
      const { max } = metricsTrend[metric.name]
      const currentValue = value.Value
      const avgPercent = (((min + max) / 2 - min) / (max - min)) * 100 || 0
      const currentPercent = ((currentValue - min) / (max - min)) * 100
      const diffFromAvg = currentPercent - avgPercent
      diffFromAverageValue = `Diff from avg: ${
        diffFromAvg > 0 ? `+${diffFromAvg.toFixed(1)}` : diffFromAvg.toFixed(1)
      }%`
    }

    if (typeof value !== "object")
      return (
        <div className={clsx(styles.metric, styles.defaultPaddings)}>-</div>
      )

    return (
      <div className={clsx(styles.metricCell)}>
        {isActive ? (
          <Typography
            color="light"
            type="caption3"
            className={styles.metricCellPopupHelper}
          >
            {diffFromAverageValue}
          </Typography>
        ) : null}
        <MetricValueChip
          value={value}
          metric={metric}
          className={clsx(styles.metric)}
          metricsTrend={metricsTrend as MetricsTrendType}
        />
      </div>
    )
  }
)

export default MetricCell
