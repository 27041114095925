import React, { useCallback } from "react"
import { SummaryEntity } from "@services/creative-analytics"
import Stack from "@components/ui/Stack/Stack"
import { Button } from "@components/ui/Button"
import Typography from "@components/ui/Typography/Typography"
import Templates from "@components/ui/Templates"
import KPIOverviewList from "./KPIOverviewList"

interface KPIOverviewProps {
  summaryReports: SummaryEntity
}

export const KPIOverview: React.FC<KPIOverviewProps> = ({ summaryReports }) => {
  const [isKpiOverviewExpanded, setIsKpiOverviewExpanded] =
    React.useState(false)

  const expandButtonText = React.useMemo(
    () => ` ${isKpiOverviewExpanded ? "Hide" : "View"} Details`,
    [isKpiOverviewExpanded]
  )

  const handleExpandButtonClick = useCallback(() => {
    setIsKpiOverviewExpanded((prev) => !prev)
  }, [])

  if (Object.keys(summaryReports).length === 0) {
    return <></>
  }

  return (
    <Stack direction="column">
      <Templates.Header
        offset="none"
        left={
          <Stack direction="row" gutter="16" align="center">
            <Typography type="h2">Overall KPIs overview</Typography>
          </Stack>
        }
        right={
          <Button
            variant="ghost"
            color="primary"
            onClick={handleExpandButtonClick}
          >
            {expandButtonText}
          </Button>
        }
      />

      <KPIOverviewList
        isExpanded={isKpiOverviewExpanded}
        summaryReports={summaryReports}
      />
    </Stack>
  )
}

export default KPIOverview
