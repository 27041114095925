import React, { FC, useState } from "react"
import Stack from "@components/ui/Stack/Stack"
import InlineLabel from "@components/ui/CheckBox/Label/Label"
import Icon from "@components/ui/Icon/Icon"
import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"

import styles from "./CheckboxDropdown.module.scss"

interface TreeNode {
  id: string
  label: string
  checked: boolean
  isContentShown: boolean
  children?: TreeNode[]
}

interface CheckboxTreeProps {
  data: TreeNode[]
}

type Path = number[]

const CheckboxDropdown: FC<CheckboxTreeProps> = ({ data: initialData }) => {
  const [data, setData] = useState(initialData)

  const toggleNode = (path: Path): void => {
    const updateNode = (nodes: TreeNode[], currentPath: Path): TreeNode[] => {
      if (currentPath.length === 0) return nodes

      const [currentIndex, ...restPath] = currentPath
      return nodes.map((node, index) => {
        if (index !== currentIndex) return node

        if (restPath.length === 0) {
          return { ...node, isContentShown: !node.isContentShown }
        }

        return {
          ...node,
          children: updateNode(node.children || [], restPath),
        }
      })
    }

    setData(updateNode(data, path))
  }

  const toggleCheck = (path: Path): void => {
    const updateChecked = (
      nodes: TreeNode[],
      currentPath: Path
    ): TreeNode[] => {
      if (currentPath.length === 0) return nodes

      const [currentIndex, ...restPath] = currentPath
      return nodes.map((node, index) => {
        if (index !== currentIndex) return node

        if (restPath.length === 0) {
          const newChecked = !node.checked
          return {
            ...node,
            checked: newChecked,
            children: node.children?.map((child) => ({
              ...child,
              checked: newChecked,
              children: updateChildrenCheck(child.children, newChecked),
            })),
          }
        }

        return {
          ...node,
          children: updateChecked(node.children || [], restPath),
          checked: inferParentCheck(
            node.children,
            restPath[0],
            !nodes[currentIndex].checked
          ),
        }
      })
    }
    setData(updateChecked(data, path))
  }

  const updateChildrenCheck = (
    children: TreeNode[] | undefined,
    checked: boolean
  ): TreeNode[] | undefined => {
    if (!children) return undefined
    return children.map((child) => ({
      ...child,
      checked,
      children: updateChildrenCheck(child.children, checked),
    }))
  }

  const inferParentCheck = (
    children: TreeNode[] | undefined,
    changedIndex: number,
    changedChecked: boolean
  ): boolean => {
    if (!children) return false
    return children.every((child, index) =>
      index === changedIndex ? changedChecked : child.checked
    )
  }

  const renderNode = (node: TreeNode, path: Path = []): JSX.Element => {
    const hasChildren = node.children && node.children.length > 0

    return (
      <div key={node.id} className={styles.nodeContainer}>
        <Stack
          direction="row"
          align="center"
          style={
            hasChildren
              ? { marginLeft: `${path.length * 12}px` }
              : { marginLeft: `${path.length * 19}px` }
          }
        >
          {hasChildren && (
            <Icon
              name="arrow-right"
              rotateAngle={node.isContentShown ? 90 : 0}
              onClick={() => toggleNode(path)}
              className="mr-2 cursor-pointer"
            />
          )}
          <InlineLabel text={node.label} color="black60Color">
            <CheckBox
              name={node.id}
              checked={node.checked}
              onChange={() => toggleCheck(path)}
            />
          </InlineLabel>
        </Stack>

        {hasChildren && node.isContentShown && (
          <div>
            {node.children?.map((child, index) =>
              renderNode(child, [...path, index])
            )}
          </div>
        )}
      </div>
    )
  }

  return (
    <Stack direction="column" gutter="4" className={styles.checkboxContainer}>
      {data.map((node, index) => renderNode(node, [index]))}
    </Stack>
  )
}

export default CheckboxDropdown
