import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import SidebarContainer from "@components/ui/Modal/SidebarContainer"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Templates from "@components/ui/Templates"
import { Button } from "@components/ui/Button"
import TextField from "@components/ui/TextField/TextField"
import { useStore } from "@store/index"
import Icon from "@components/ui/Icon/Icon"
import CheckboxDropdown from "./CheckboxDropdown/CheckboxDropdown"

import styles from "./SideBarMetaLink.module.scss"

interface SideBarMetaLinkProps {
  show: boolean
  onClose: () => void
}

const SideBarMetaLink: FC<SideBarMetaLinkProps> = observer(
  ({ show, onClose }) => {
    const [searchQuery, setSearchQuery] = useState<string>("")

    const {
      accountStore: { accountId },
      productFeedStore: {
        metaAds: { loadAdsLisst, adsList },
      },
    } = useStore()
    useEffect(() => {
      if (accountId) {
        loadAdsLisst(accountId)
      }
    }, [accountId])

    return (
      <SidebarContainer className={styles.root} isOpen={show} onClose={onClose}>
        <div className={styles.containerPading}>
          <Stack direction="column" gutter="24">
            <Typography type="h2" weight="bold">
              Link to Meta Ads
            </Typography>
            <Typography type="h2">
              Select Meta Ads you want to connect products to
            </Typography>
          </Stack>
          <TextField
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.currentTarget.value)
            }}
            className={styles.input}
            before={<Icon name="search" />}
            placeholder="Search"
          />
        </div>

        <CheckboxDropdown
          data={[
            {
              id: "1",
              label: "Root 1",
              checked: false,
              isContentShown: false,
              children: [
                {
                  id: "1-1",
                  label: "Child 1",
                  checked: false,
                  isContentShown: false,
                  children: [
                    {
                      id: "1-1-1",
                      label: "Child 1",
                      checked: false,
                      isContentShown: false,
                    },
                  ],
                },
                {
                  id: "1-1",
                  label: "Child 1",
                  checked: false,
                  isContentShown: false,
                },
                {
                  id: "1-1",
                  label: "Child 1",
                  checked: false,
                  isContentShown: false,
                },
              ],
            },
          ]}
        />
        <Templates.Header
          className={styles.footer}
          offset="none"
          right={
            <Stack direction="row">
              <Button color="secondary" onClick={() => {}}>
                Cancel
              </Button>
              <Button color="primary" onClick={() => {}}>
                Apply
              </Button>
            </Stack>
          }
        />
      </SidebarContainer>
    )
  }
)

export default SideBarMetaLink
