import React from "react"

import {
  MetricDescription,
  MetricsTrendType,
  MetricType,
} from "@framework/types/metrics"
import { mbNoData } from "@services/utils"
import { getTrendGradientColor } from "./utils"

export const MetricValueChip: React.FC<{
  metric: MetricDescription
  metricsTrend?: MetricsTrendType
  value?: MetricType | null
  className?: string
}> = ({ metric, metricsTrend, value, className }) => {
  let color = ""
  if (metricsTrend && metricsTrend[metric.name] && value?.Value) {
    color = getTrendGradientColor(
      metricsTrend[metric.name]?.trend,
      value?.Value,
      metric?.trendDirection
    )
  }
  return (
    <div
      className={className}
      style={{
        background: color,
      }}
    >
      {mbNoData(value?.Value, metric.formatter)}
    </div>
  )
}

export default MetricValueChip
