import React, { useCallback, useEffect } from "react"
import clsx from "clsx"
import { useHistory } from "react-router-dom"
import { observer } from "mobx-react-lite"

import Icon from "@components/ui/Icon/Icon"
import TextField from "@components/ui/TextField/TextField"
import Typography from "@components/ui/Typography/Typography"
import { ButtonGroup, Button } from "@components/ui/Button"
import { useStore } from "@store/index"
import IconButton from "@components/ui/Button/IconButton"
import SimpleSelect from "@components/ui/DropDown/SimpleSelect"
import Label from "@components/ui/CheckBox/Label/Label"
import BackButton from "@framework/prototypes/BackButton"
import Stack from "@components/ui/Stack/Stack"
import debounce from "lodash/debounce"
import { ViewType } from "../types"

import styles from "./MainHeader.module.scss"

const viewOptions: ViewType[] = ["simple", "comparison"]

export interface MainHeaderProps {
  className?: string
  onSetthings?: () => void
  onFilter?: any
  view?: ViewType
  onViewChange?: (value: ViewType) => void
}

export const MainHeader: React.FC<MainHeaderProps> = observer(
  ({ onFilter, view, className, onViewChange, onSetthings }) => {
    const history = useHistory()

    const {
      productFeedStore: { filter, list },
      accountStore: { account },
    } = useStore()

    const reset = useCallback(
      debounce(() => {
        list.reloadProductFeed(true)
      }, 600),
      []
    )

    const handleGoBack = () => history.push("./")

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      filter.search.setQuery(e.target.value)
      reset()
    }

    useEffect(
      () => () => {
        filter.search.setQuery()
      },
      []
    )

    return (
      <div className={clsx(styles.root, className)}>
        <div className={styles.rightSection}>
          <div className={styles.title}>
            <BackButton onClick={handleGoBack} />
            <Typography type="h1" color="darkBlue" bold>
              Product Feed List
            </Typography>
          </div>

          <TextField
            value={filter.search.query}
            onChange={handleSearch}
            className={styles.input}
            before={<Icon name="search" />}
            placeholder="Search"
          />

          <Label text="View" textPosition="before">
            <SimpleSelect
              className={styles.input}
              value={view}
              onSelect={onViewChange}
              options={viewOptions}
            />
          </Label>
        </div>
        <Stack direction="row">
          {account?.metaCredentials?.ID && (
            <Button
              onClick={onSetthings}
              color="secondary"
              startIcon={{ name: "settings" }}
            >
              Settings
            </Button>
          )}

          <ButtonGroup className={styles.leftSection}>
            <Button
              onClick={onFilter}
              color="secondary"
              startIcon={{ name: "filter" }}
            >
              Filter • {filter.activeFilterCount}
            </Button>
            <IconButton
              onClick={filter.resetAll}
              variant="contained"
              color="secondary"
              title="Reset filters"
            >
              <Icon name="reset" />
            </IconButton>
          </ButtonGroup>
        </Stack>
      </div>
    )
  }
)

export default MainHeader
