import React, { useEffect, useState } from "react"
import { Button } from "@components/ui/Button"
import ButtonDatePicker from "@components/ui/DatePicker/ButtonDatePicker"
import SimpleFilterSelect from "@components/ui/FiltersDropDown/SimpleFilterSelect"
import SimpleFiltersMultiselect from "@components/ui/FiltersDropDown/SimpleFiltersMultiselect"
import Icon from "@components/ui/Icon/Icon"
import Stack from "@components/ui/Stack/Stack"
import {
  defaultSummaryMetrics,
  summaryGroupByObjectOptions,
  summaryMetricOptions,
} from "@framework/types/creativeAnalytics"
import { ADSAnalyticsSummaryGroupType } from "@services/creative-analytics"
import { useStore } from "@store/index"
import Box from "@components/ui/Box/Box"
import { DateRangeOption } from "@components/ui/DatePicker/types"
import useOptionSearchList from "@framework/prototypes/useOptionsSearchList"
import { apiDateFormatter } from "@services/utils"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import styles from "./index.module.scss"

const groupByOptionsArray = Object.entries(summaryGroupByObjectOptions).map(
  ([key, value]) => ({
    value: key as ADSAnalyticsSummaryGroupType,
    label: value,
  })
)

const metricsOptionsArray = Object.entries(summaryMetricOptions).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
)

interface SummaryControlPanelProps {
  onMetricsFilter?: (metrics: string[]) => void
  metricOptionsValues: string[]
  setMetricOptionsValues: React.Dispatch<React.SetStateAction<string[]>>
}

const SummaryControlPanel: React.FC<SummaryControlPanelProps> = observer(
  ({ onMetricsFilter, metricOptionsValues, setMetricOptionsValues }) => {
    const {
      accountStore: { accountId },
      creativeAnalyticsStore: { getADSCards, adsCardsData, isLoading },
      analyticsFiltersStore: { datePickerOptions, allFilters, setAllFilters },
    } = useStore()

    const alert = useAlert()

    const { view, summurygroupingType, period } = allFilters
    const [showMetrics, setShowMetrics] = useState(false)
    const [showGroupBy, setShowGroupBy] = useState(false)
    const [groupByValue, setGroupByValue] =
      useState<ADSAnalyticsSummaryGroupType>(
        groupByOptionsArray.find((opt) => opt.value === summurygroupingType)
          ?.value ?? "AllAds"
      )
    const metricsOptions = useOptionSearchList({
      list: metricsOptionsArray ?? [],
    })

    const groupByOptions = useOptionSearchList({
      list: groupByOptionsArray ?? [],
    })

    useEffect(() => {
      setGroupByValue("AllAds")
      setMetricOptionsValues(defaultSummaryMetrics)
    }, [adsCardsData])

    useEffect(() => {
      if (onMetricsFilter) onMetricsFilter(metricOptionsValues)
    }, [metricOptionsValues])

    useEffect(() => {
      if (onMetricsFilter) onMetricsFilter(metricOptionsValues)
    }, [])

    useEffect(() => {
      setAllFilters({
        ...allFilters,
        sortByMetric: { value: "Cost", order: "desc" },
      })
    }, [])

    useEffect(() => {
      const campaigns: Set<string> = new Set()
      const adSets: Set<string> = new Set()
      if (adsCardsData.AdCreatives.length) {
        adsCardsData.AdCreatives.forEach((c) => {
          if (c.CampaignName) {
            campaigns.add(c.CampaignName)
          }
        })
        adsCardsData.AdCreatives.forEach((c) => {
          if (c.AdsetName) {
            adSets.add(c.AdsetName)
          }
        })
      }
    }, [adsCardsData])

    const handleGroupBy = (value: ADSAnalyticsSummaryGroupType) => {
      if (view === "table") {
        setAllFilters({
          ...allFilters,
          view: "card",
          summurygroupingType: value,
        })
      } else {
        setAllFilters({ ...allFilters, summurygroupingType: value })
      }
      setGroupByValue(value)
    }

    const handleMetricsOptions = (value: string) => {
      setMetricOptionsValues((prev) =>
        prev.includes(value)
          ? prev.filter((it) => it !== value)
          : [...prev, value]
      )
    }

    const handleSetPeriod = (period: DateRangeOption) => {
      setAllFilters({ ...allFilters, period })
      if (!accountId) return
      getADSCards(accountId, {
        from: apiDateFormatter(period.range[0]),
        to: apiDateFormatter(period.range[1]),
      }).then((res) => {
        if (res) alert.error(res)
      })
    }

    if (isLoading) return <div />

    return (
      <Box>
        <Stack
          className={styles.reportControlPanel}
          direction="row"
          gutter="8"
          justify="space-between"
        >
          <Stack direction="row" gutter="8">
            <ButtonDatePicker
              range={period}
              onRangeChange={handleSetPeriod}
              rangeOptions={datePickerOptions}
            />
            <div className={styles.filterButton}>
              <Button
                color="secondary"
                before={<Icon name="stack" />}
                onClick={() => setShowGroupBy((prev) => !prev)}
                disabled={view === "table"}
              >
                {summaryGroupByObjectOptions[groupByValue]}
              </Button>
              {showGroupBy && (
                <div className={styles.groupByMenu}>
                  <SimpleFilterSelect
                    className={styles.select}
                    onSelect={(value) =>
                      handleGroupBy(value as ADSAnalyticsSummaryGroupType)
                    }
                    onClickOutside={() => setShowGroupBy(false)}
                    value={groupByValue}
                    {...groupByOptions}
                  />
                </div>
              )}
            </div>
            <div className={styles.filterButton}>
              <Button
                color="secondary"
                before={<Icon name="eye" />}
                onClick={() => setShowMetrics((prev) => !prev)}
              >
                Metrics
              </Button>
              {showMetrics && (
                <div className={styles.metricsMenu}>
                  <SimpleFiltersMultiselect
                    className={styles.select}
                    inputClassName={styles.selectInput}
                    withSearch
                    onSelect={handleMetricsOptions}
                    value={metricOptionsValues}
                    placeholder="Search metrics"
                    onClickOutside={() => setShowMetrics(false)}
                    {...metricsOptions}
                  />
                </div>
              )}
            </div>
          </Stack>
        </Stack>
      </Box>
    )
  }
)

export default SummaryControlPanel
