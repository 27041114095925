import {
  MetricsTrendType,
  MetricType,
  PercentageTrendRange,
} from "@framework/types/metrics"
import { AnalyticsCard } from "@framework/types/creativeAnalytics"

export type PercentageTrendRangeKeys = keyof PercentageTrendRange
export const gradient: Record<PercentageTrendRangeKeys, string> = {
  "0 - 10%": "var(--metric_0-10_color)",
  "10% - 20%": "var(--metric_10-20_color)",
  "20% - 30%": "var(--metric_20-30_color)",
  "30% - 40%": "var(--metric_30-40_color)",
  "40% - 50%": "var(--metric_40-50_color)",
  "50% - 60%": "var(--metric_50-60_color)",
  "60% - 70%": "var(--metric_60-70_color)",
  "70% - 80%": "var(--metric_70-80_color)",
  "80% - 90%": "var(--metric_80-90_color)",
  "90% - 100%": "var(--metric_90-100_color)",
}
export const gradientReverted: Record<PercentageTrendRangeKeys, string> = {
  "0 - 10%": "var(--metric-reverted_0-10_color)",
  "10% - 20%": "var(--metric-reverted_10-20_color)",
  "20% - 30%": "var(--metric-reverted_20-30_color)",
  "30% - 40%": "var(--metric-reverted_30-40_color)",
  "40% - 50%": "var(--metric-reverted_40-50_color)",
  "50% - 60%": "var(--metric-reverted_50-60_color)",
  "60% - 70%": "var(--metric-reverted_60-70_color)",
  "70% - 80%": "var(--metric-reverted_70-80_color)",
  "80% - 90%": "var(--metric-reverted_80-90_color)",
  "90% - 100%": "var(--metric-reverted_90-100_color)",
}

export const getTrendGradientColor = (
  trend: PercentageTrendRange,
  value: number | undefined,
  trendDirection?: "reversed" | "normal"
) => {
  if (value && trend) {
    let result = ""
    if (trendDirection === "reversed") {
      Object.entries(trend).forEach(([key, val]) => {
        if (val.min <= value && value <= val.max) {
          // @ts-ignore
          result = gradientReverted[key]
        }
      })
    } else {
      Object.entries(trend).forEach(([key, val]) => {
        if (val.min <= value && value <= val.max) {
          // @ts-ignore
          result = gradient[key]
        }
      })
    }
    return result
  }
  return "transparent"
}

export const getTrendGradientPosition = (
  trend: PercentageTrendRange,
  value: number | undefined
) => {
  if (value && trend) {
    let result = ""
    Object.entries(trend).forEach(([key, val]) => {
      if (val.min <= value && value <= val.max) {
        // @ts-ignore
        result = key
      }
    })
    return result
  }
  return "50% - 60%"
}

function createPercentageRanges(
  min: number,
  max: number
): PercentageTrendRange {
  const range = (max - min) / 10
  return {
    "0 - 10%": { min, max: min + range },
    "10% - 20%": { min: min + range, max: min + 2 * range },
    "20% - 30%": { min: min + 2 * range, max: min + 3 * range },
    "30% - 40%": { min: min + 3 * range, max: min + 4 * range },
    "40% - 50%": { min: min + 4 * range, max: min + 5 * range },
    "50% - 60%": { min: min + 5 * range, max: min + 6 * range },
    "60% - 70%": { min: min + 6 * range, max: min + 7 * range },
    "70% - 80%": { min: min + 7 * range, max: min + 8 * range },
    "80% - 90%": { min: min + 8 * range, max: min + 9 * range },
    "90% - 100%": { min: min + 9 * range, max },
    // "20% - 40%": { min: min + range, max: min + 2 * range },
    // "40% - 60%": { min: min + 2 * range, max: min + 3 * range },
    // "60% - 80%": { min: min + 3 * range, max: min + 4 * range },
    // "80% - 100%": { min: min + 4 * range, max },
  }
}
export const calculateMetricsTrend = (
  data: AnalyticsCard[],
  activeMetrics: string[]
) => {
  const result: MetricsTrendType = {}
  activeMetrics.forEach((metric) => {
    const metricName = metric
    data.forEach((card) => {
      const m = card[metricName] as MetricType
      if (m.Value) {
        result[metricName] = {
          ...result[metricName],
          min: Math.min(result[metricName]?.min || 0, Number(m.Value)),
          max: Math.max(result[metricName]?.max || 0, Number(m.Value)),
        }
      }
    })
  })
  Object.entries(result).forEach(([metricName, value]) => {
    result[metricName].trend = createPercentageRanges(value.min, value.max)
  })
  return result
}

export default null
