import React, { FC, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"
import { useStore } from "@store/index"
import { ShopifySettingOptions } from "@framework/types/account"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import BaseSideBarSetting from "./BaseSideBarSettings"

interface SideBarSettingProps {
  show: boolean
  onClose: () => void
}

const SideBarSetting: FC<SideBarSettingProps> = observer(
  ({ show, onClose }) => {
    const alert = useAlert()
    const {
      accountStore: { accountId },
      productFeedStore: { settings },
    } = useStore()

    const handleApply = (settingOptions: ShopifySettingOptions) => {
      if (accountId) {
        alert.success(
          <AlertMessage title="Settings is saved! " description="" />
        )
        settings.setNewSettings(accountId, settingOptions)
      }
    }

    const handleCancel = (settingOptions: ShopifySettingOptions) => {
      handleApply(settingOptions)
      onClose()
    }

    useEffect(() => {
      if (accountId) {
        settings.loadSettingOptions(accountId)
      }
    }, [accountId, settings])

    return (
      <BaseSideBarSetting
        onApply={handleApply}
        onClose={onClose}
        onCancel={handleCancel}
        show={show}
        emailBackInStock={!!settings.getSettingOptions.emailBackInStock}
        emailOutOfStock={!!settings.getSettingOptions.emailOutOfStock}
        metaAdsPauseOutOfStock={
          !!settings.getSettingOptions.metaAdsPauseOutOfStock
        }
        metaAdsStartInStock={!!settings.getSettingOptions.metaAdsStartInStock}
      />
    )
  }
)

export default SideBarSetting
