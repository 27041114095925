import React from "react"
import { SummaryEntity } from "@services/creative-analytics"
import { KPI_OVERVIEW_TYPE_DESCRIPTION } from "@utils/enums"
import { metricsToDisplay } from "@store/reports/constants"
import KPIOverviewListItem from "../KPIOverviewListItem"
import styles from "./index.module.scss"

interface KPIOverviewListProps {
  summaryReports: SummaryEntity
  isExpanded: boolean
}

export const KPIOverviewList: React.FC<KPIOverviewListProps> = ({
  summaryReports,
  isExpanded,
}) => {
  if (!summaryReports) {
    return null
  }

  return (
    <div className={styles.container}>
      {metricsToDisplay.map((metricName) => {
        const props = summaryReports[metricName]
        if (props) {
          return (
            <KPIOverviewListItem
              key={metricName}
              isExpanded={isExpanded}
              change={props.Change}
              title={metricName}
              description={KPI_OVERVIEW_TYPE_DESCRIPTION[metricName]}
              total={props.Value}
              avg={props.Avg}
              top={props.Top}
              lowest={props.Lowest}
            />
          )
        }
        return <></>
      })}
    </div>
  )
}

export default KPIOverviewList
