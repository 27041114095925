import React, {
  useState,
  CSSProperties,
  useMemo,
  PropsWithChildren,
} from "react"
import ReactDOM from "react-dom"
import styles from "./index.module.scss"
import Typography from "../Typography/Typography"

interface TooltipProps {
  text: string
  position?: "top" | "bottom" | "left" | "right"
}

const Tooltip: React.FC<PropsWithChildren<TooltipProps>> = ({
  text,
  position = "top",
  children,
}) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [tooltipPosition, setTooltipPosition] = useState<DOMRect | null>(null)
  const triggerRef = React.useRef<HTMLSpanElement>(null)

  const handleMouseEnter = () => {
    setVisible(true)
    // Delay to allow tooltip positioning logic to run
    if (triggerRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect()
      setTooltipPosition(triggerRect)
    }
  }

  const handleMouseLeave = () => {
    setVisible(false)
  }

  const calculateTooltipStyles = useMemo<CSSProperties | null>(() => {
    if (!tooltipPosition) return null

    let styles = {} as CSSProperties

    switch (position) {
      case "top":
        styles = {
          bottom: window.innerHeight - tooltipPosition.top + 10,
          left: tooltipPosition.left + tooltipPosition.width / 2,
        }
        break
      case "bottom":
        styles = {
          top: tooltipPosition.bottom + 10,
          left: tooltipPosition.left + tooltipPosition.width / 2,
        }
        break
      case "left":
        styles = {
          top: tooltipPosition.top + tooltipPosition.height / 2,
          right: window.innerWidth - tooltipPosition.left + 10,
        }
        break
      case "right":
        styles = {
          top: tooltipPosition.top + tooltipPosition.height / 2,
          left: tooltipPosition.right + 10,
        }
        break
      default:
        break
    }

    return styles
  }, [position, tooltipPosition])

  return (
    <div
      className={styles.tooltipContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span ref={triggerRef}>{children}</span>
      {visible &&
        calculateTooltipStyles &&
        ReactDOM.createPortal(
          <div
            className={`${styles.tooltipContent} ${
              styles[`tooltip-${position}`]
            }`}
            style={calculateTooltipStyles}
          >
            <Typography type="h5" color="dark">
              {text}
            </Typography>
          </div>,
          document.body // Renders the tooltip directly to the body
        )}
    </div>
  )
}

export default Tooltip
