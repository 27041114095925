import React, { useEffect, useState } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Modal from "@components/ui/Modal/Modal"
import Icon from "@components/ui/Icon/Icon"
import Templates from "@components/ui/Templates"
import IconButton from "@components/ui/Button/IconButton"
import { AnalyticsCard } from "@framework/types/creativeAnalytics"
import { useStore } from "@root/store"
import { MetricsTrendType } from "@framework/types/metrics"
import { useAlert } from "react-alert"
import Typography from "@components/ui/Typography/Typography"
import { cutStringSize50 } from "@utils/stringUtils"
import DetailsSection from "./DetailsSection"
import MediaSection from "./MediaSection"
import StatusBadge from "../StatusBadge"
import ReportTitle from "../ReportTitle"

import styles from "./ReportDetails.module.sass"

interface ReportDetailsProps {
  reportDetails: AnalyticsCard
  metricsTrend: MetricsTrendType
  open: boolean
  onClose: () => void
  externalReportAccountId?: number
  guestToken?: string
  onPreviousClick: (id: string) => void
  onNextClick: (id: string) => void
}

export const ReportDetailsModal: React.FC<ReportDetailsProps> = observer(
  ({
    open,
    onClose,
    reportDetails,
    metricsTrend,
    externalReportAccountId,
    guestToken,
    onPreviousClick,
    onNextClick,
  }) => {
    const {
      accountStore: { accountId },
      creativeAnalyticsStore: {
        getDropOffHistogram,
        dropOffHistogramData,
        isDropOffHistogramLoading,
        cleanDropOffHistogramData,
      },
    } = useStore()

    const alert = useAlert()
    const [duration, setDuration] = useState<number | null>(null)

    const handleDuration = (value: number) => {
      setDuration(value)
    }

    useEffect(() => () => setDuration(null), [])

    useEffect(() => {
      cleanDropOffHistogramData()
      if (
        reportDetails &&
        externalReportAccountId &&
        guestToken &&
        reportDetails.Type === "VIDEO"
      ) {
        if (reportDetails.VideoSource === "instagram_raw" && duration) {
          getDropOffHistogram(
            externalReportAccountId,
            reportDetails.Id,
            duration,
            {
              token: guestToken,
            }
          ).then((res) => {
            if (res) alert.error(res)
          })
        } else if (reportDetails.VideoSource === "facebook_plugin") {
          getDropOffHistogram(externalReportAccountId, reportDetails.Id, null, {
            token: guestToken,
          }).then((res) => {
            if (res) alert.error(res)
          })
          return
        }
      }
      if (
        reportDetails &&
        reportDetails.Type === "VIDEO" &&
        !externalReportAccountId &&
        !guestToken &&
        accountId
      ) {
        if (reportDetails.VideoSource === "instagram_raw" && duration) {
          getDropOffHistogram(accountId, reportDetails.Id, duration).then(
            (res) => {
              if (res) alert.error(res)
            }
          )
        } else if (reportDetails.VideoSource === "facebook_plugin") {
          getDropOffHistogram(accountId, reportDetails.Id, null).then((res) => {
            if (res) alert.error(res)
          })
        }
      }
    }, [accountId, reportDetails, guestToken, duration])

    return (
      <Modal
        className={clsx(styles.root)}
        isOpen={open}
        onClose={onClose}
        hideHeader
      >
        <div className={styles.header}>
          <Templates.TripleHeader
            left={
              <div className={styles.title}>
                <ReportTitle>
                  {cutStringSize50(reportDetails?.Name)}
                </ReportTitle>
                <StatusBadge status={reportDetails?.Status} short />
              </div>
            }
            right={
              <>
                <IconButton
                  variant="contained"
                  color="secondary"
                  onClick={() => onPreviousClick(reportDetails?.Id)}
                >
                  <Icon name="arrow-right" rotateAngle={180} />
                </IconButton>
                <IconButton
                  variant="contained"
                  color="secondary"
                  onClick={() => onNextClick(reportDetails?.Id)}
                >
                  <Icon name="arrow-right" />
                </IconButton>
                <IconButton
                  variant="contained"
                  color="secondary"
                  onClick={onClose}
                >
                  <Icon name="cross" />
                </IconButton>
              </>
            }
          />
        </div>

        <Typography type="body2" className={styles.titleTooltip}>
          {reportDetails?.Name}
        </Typography>

        <div className={clsx(styles.body)}>
          <MediaSection data={reportDetails} handleDuration={handleDuration} />

          <DetailsSection
            data={reportDetails}
            metricsTrend={metricsTrend}
            dropOffHistogramData={dropOffHistogramData}
            isLoading={isDropOffHistogramLoading}
          />
        </div>
      </Modal>
    )
  }
)

export default ReportDetailsModal
