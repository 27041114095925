import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"
import { FormikProvider, useFormik, useFormikContext } from "formik"
import * as yup from "yup"
import { nanoid } from "nanoid"

import Modal, { ModalProps } from "@components/ui/Modal/Modal"
import Stack from "@components/ui/Stack/Stack"
import { Button } from "@components/ui/Button"
import ModalTemplate from "@components/ui/Modal/ModalTemplate"
import FormSimpleSelectField from "@framework/prototypes/FormSimpleSelectFiled"
import FormTextField from "@framework/prototypes/FormTextField"
import Typography from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"
import { metricNamesList } from "@framework/types/metrics"
import { renderMetricOption } from "@framework/constants/metrics"

import IconButton from "@components/ui/Button/IconButton"
import { WinnersLosersSettings } from "@pages/MetaAdsChannel/Winners/Winners"
import { filterEqualityTypes } from "@framework/types/creativeAnalytics"
import {
  WinnersLosersMetricsEntity,
  WinnersLosersUpdateEntity,
  WinnersLosersUpdateMetricsEntity,
} from "@services/creative-analytics"
import styles from "./FacebookReportWinnersSettingsModal.module.sass"

const defaultMetricsOptions = [...metricNamesList]

const periodOption = ["days", "weeks", "months"]

const operatorOptions = Object.keys(filterEqualityTypes)

const conditionRowValidation = yup.object({
  id: yup.string().default(() => nanoid()),

  metric_name: yup
    .string()
    .label("Metric")
    .required()
    .default(defaultMetricsOptions[0]),

  operator: yup
    .string()
    .label("Operator")
    .required()
    .default(operatorOptions[0]),

  value1: yup.number().label("Right Operand").required(),
})

const validationSchema = yup.object({
  // New section
  new_period: yup.number().label("Period").required(),
  new_period_unit: yup
    .string()
    .label("Period unit")
    .oneOf(periodOption)
    .default(periodOption[0])
    .required(),

  // Scaling section
  scaling_threshold: yup.number().label("Amount").required(),

  // Winners
  winners: yup
    .array()
    .of(conditionRowValidation)
    .default([conditionRowValidation.getDefault()]),

  // Losers
  losers: yup
    .array()
    .of(conditionRowValidation)
    .default([conditionRowValidation.getDefault()]),
})

export type ConditionRowData = yup.InferType<typeof conditionRowValidation>

export type FormData = yup.InferType<typeof validationSchema>

export interface Props extends ModalProps {
  data: WinnersLosersSettings
  isLoading: boolean
  onSubmit: (values: WinnersLosersUpdateEntity) => void
}

export const FacebookReportWinnersSettingsModal: React.FC<Props> = observer(
  ({ data, isLoading, onSubmit, ...rest }) => {
    const initValue = useMemo(
      () =>
        validationSchema.cast(
          {
            new_period: data.new_period,
            new_period_unit: data.new_period_unit,
            scaling_threshold: data.scaling_threshold,
            winners: data?.winnersFilters,
            losers: data?.losersFilters,
          },
          { stripUnknown: true }
        ) as FormData,
      []
    )

    const handleSubmit = async (form: FormData) => {
      const winners: WinnersLosersUpdateMetricsEntity[] = form.winners.map(
        (it: WinnersLosersMetricsEntity) => ({
          operator: it.operator,
          metric_name: it.metric_name,
          value_1: Number(it.value1),
          value_2: null,
          category: "Winners",
          unit: "value",
        })
      )
      const losers: WinnersLosersUpdateMetricsEntity[] = form.losers.map(
        (it: WinnersLosersMetricsEntity) => ({
          operator: it.operator,
          metric_name: it.metric_name,
          value_1: Number(it.value1),
          value_2: null,
          category: "Losers",
          unit: "value",
        })
      )
      const filters: WinnersLosersUpdateMetricsEntity[] = [
        ...winners,
        ...losers,
      ]
      const result: WinnersLosersUpdateEntity = {
        new_period: form.new_period,
        new_period_unit: form.new_period_unit as "days" | "weeks" | "months",
        scaling_threshold: Number(form.scaling_threshold),
        metric_criterias: filters,
      }
      onSubmit(result)
      rest.onClose?.()
    }

    const formik = useFormik<FormData>({
      initialValues: initValue,
      validationSchema,
      onSubmit: handleSubmit,
    })

    return (
      <Modal {...rest} hideHeader>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Stack
              className={styles.root}
              gutter="0"
              direction="column"
              align="stretch"
            >
              <ModalTemplate.Header title="Settings" />

              <ModalTemplate.Body>
                <Stack direction="column" gutter="16" align="stretch">
                  <section className={styles.section}>
                    <Stack direction="column" gutter="12" align="stretch">
                      <Typography type="h4" weight="bold">
                        New
                      </Typography>

                      <div className={styles.pair}>
                        <Typography type="body1">
                          Launched within the last
                        </Typography>

                        <div className={styles.row}>
                          <FormTextField name="new_period" type="number" />

                          <FormSimpleSelectField
                            name="new_period_unit"
                            placement="bottom-end"
                            placeholder="Select period..."
                            options={periodOption}
                          />
                        </div>
                      </div>
                    </Stack>
                  </section>

                  <section className={styles.section}>
                    <Stack direction="column" gutter="12" align="stretch">
                      <Typography type="h4" weight="bold">
                        Scaling
                      </Typography>

                      <div className={styles.pair}>
                        <Typography type="body1">
                          Spend greater than or equal to
                        </Typography>

                        <div className={styles.row}>
                          <FormTextField name="scaling_threshold" before="$" />
                        </div>
                      </div>
                    </Stack>
                  </section>

                  <section className={styles.section}>
                    <Stack direction="column" gutter="12" align="stretch">
                      <Stack direction="row" align="center" gutter="4">
                        <Typography type="h1" color="lightGreen">
                          <Icon name="vip-crown" />
                        </Typography>
                        <Typography type="h4" weight="bold">
                          Winners
                        </Typography>
                      </Stack>

                      <ConditionsListForm name="winners" />
                    </Stack>
                  </section>

                  <section className={styles.section}>
                    <Stack direction="column" gutter="12" align="stretch">
                      <Stack direction="row" align="center" gutter="4">
                        <Typography type="h1" color="red">
                          <Icon name="long-arrow-right" rotateAngle={45} />
                        </Typography>
                        <Typography type="h4" weight="bold">
                          Losers
                        </Typography>
                      </Stack>

                      <ConditionsListForm name="losers" />
                    </Stack>
                  </section>
                </Stack>
              </ModalTemplate.Body>

              <ModalTemplate.Footer>
                <Button
                  size="big"
                  color="secondary"
                  disabled={isLoading}
                  onClick={rest.onClose}
                >
                  Cancel
                </Button>
                <Button size="big" type="submit" disabled={isLoading}>
                  Apply
                </Button>
              </ModalTemplate.Footer>
            </Stack>
          </form>
        </FormikProvider>
      </Modal>
    )
  }
)

export default FacebookReportWinnersSettingsModal

const ConditionsListForm: React.FC<{
  name: keyof FormData
  minRows?: number
}> = ({ name: listName, minRows = 1 }) => {
  const formik = useFormikContext<FormData>()

  const list: Partial<ConditionRowData>[] = formik.values[listName] ?? []

  const handleAddRow = () => {
    list.push(conditionRowValidation.getDefault())
    formik.setFieldValue(listName, list)
  }

  const handleRemoveRow = (rowId: string) => () => {
    if (list.length < minRows) return

    formik.setFieldValue(
      listName,
      list.filter((it) => it.id !== rowId)
    )
  }

  return (
    <Stack direction="column" gutter="8" align="stretch">
      {list.map((it, idx) => {
        const prefix = `${listName}[${idx}]`
        return (
          <div className={styles.row} key={it.id}>
            <FormSimpleSelectField
              name={`${prefix}.metric_name`}
              placement="bottom-end"
              placeholder="Select metric..."
              options={defaultMetricsOptions}
              renderValue={renderMetricOption}
            />

            <FormSimpleSelectField
              name={`${prefix}.operator`}
              placement="bottom-end"
              placeholder="Select operator..."
              options={operatorOptions}
              renderValue={(it) => filterEqualityTypes[it]}
            />

            <FormTextField
              name={`${prefix}.value1`}
              placeholder="Enter value..."
            />

            {it.id && list.length > minRows && (
              <IconButton
                variant="contained"
                color="secondary"
                onClick={handleRemoveRow(it.id)}
                className={styles.short}
              >
                <Icon name="trash" />
              </IconButton>
            )}
          </div>
        )
      })}

      <Button
        variant="ghost"
        color="primary"
        before={<Icon name="plus" />}
        onClick={handleAddRow}
      >
        Add metric
      </Button>
    </Stack>
  )
}
