import React from "react"
import Box from "@components/ui/Box/Box"
import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"
import Tooltip from "@components/ui/TooltipV2"
import Divider from "@components/ui/Divider/Divider"
import { positiveTransformDirection } from "@components/ui/Typography/Trend/utils"
import { getMetricDescriptor } from "@framework/constants/metrics"
import Trend from "@components/ui/Typography/Trend/Trend"
import { percentageFormatter } from "@services/utils"

import styles from "./index.module.scss"

interface KPIOverviewListItemProps {
  title: string
  description: string
  total: number
  change: number
  avg: number
  top: number
  lowest: number
  isExpanded: boolean
}

export const KPIOverviewListItem: React.FC<KPIOverviewListItemProps> = ({
  title,
  description,
  total,
  change,
  avg,
  top,
  lowest,
  isExpanded,
}) => {
  const metric = getMetricDescriptor(title)
  const dolarMetric = getMetricDescriptor("Cost")
  const trendFormatter = metric.trendFormat ?? positiveTransformDirection
  return (
    <Box className={styles.container}>
      <Stack gutter="4">
        <Stack direction="row" align="center" gutter="4">
          <Typography type="h3" weight="semibold">
            {metric.label}
          </Typography>
          <Tooltip position="bottom" text={description}>
            <Icon style={{ color: "gray" }} name="information-solid" />
          </Tooltip>
        </Stack>
        <Stack gutter="0">
          <Typography type="h5" color="black60Color">
            Total:
          </Typography>
          <Typography type="h1" weight="semibold">
            {metric.formatter(total ?? 0)}
          </Typography>
        </Stack>
      </Stack>
      <Trend type="h5" {...trendFormatter(change)}>
        {percentageFormatter(change * 100)}
      </Trend>

      {isExpanded && (
        <>
          <Divider />
          <Stack gutter="8">
            <Stack gutter="0">
              <Typography type="h5" color="black60Color">
                AVG:
              </Typography>
              <Typography type="h3" weight="semibold">
                {dolarMetric.formatter(avg ?? 0)}
              </Typography>
            </Stack>

            <Stack gutter="0">
              <Typography type="h5" color="black60Color">
                Top:
              </Typography>
              <Typography type="h3" weight="semibold">
                {dolarMetric.formatter(top ?? 0)}
              </Typography>
            </Stack>

            <Stack gutter="0">
              <Typography type="h5" color="black60Color">
                Lowest:
              </Typography>
              <Typography type="h3" weight="semibold">
                {dolarMetric.formatter(lowest ?? 0)}
              </Typography>
            </Stack>
          </Stack>
        </>
      )}
    </Box>
  )
}

export default KPIOverviewListItem
