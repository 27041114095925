import React, { FC, useState } from "react"
import Stack from "@components/ui/Stack/Stack"
import { Button } from "@components/ui/Button"
import { AnalyticsCard } from "@framework/types/creativeAnalytics"
import useOutsideClick from "@framework/hooks/useOutsideClick"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/Button/IconButton"
import CircleLoader from "@components/ui/Loader/CircleLoader"
import clsx from "clsx"
import styles from "./Modals.module.scss"

interface VideoPreviewModalProps {
  data: AnalyticsCard
  onClose: () => void
  onDetailsOpen: (data: AnalyticsCard) => void
  positionLeft?: boolean
}

const VideoPreviewModal: FC<VideoPreviewModalProps> = ({
  data,
  positionLeft,
  onClose,
  onDetailsOpen,
}) => {
  const ref = useOutsideClick(onClose)
  const [isIframeLoading, setIsIframeLoading] = useState<boolean>(true)
  const handleOnIFrameLoad = () => {
    setIsIframeLoading(false)
  }
  return (
    <section
      ref={ref}
      className={clsx(styles.modalVideoPreview, {
        [styles.modalLeftPosition]: positionLeft,
      })}
    >
      <Stack direction="row" justify="space-between">
        <IconButton
          onClick={() => onDetailsOpen(data)}
          variant="contained"
          color="secondary"
        >
          <Icon name="file-info" />
        </IconButton>
        <Button
          color="secondary"
          onClick={onClose}
          after={<Icon name="cross" />}
        >
          Close
        </Button>
      </Stack>
      <div className={styles.modalVideoPreviewIframeWrap}>
        {data.VideoSource === "facebook_plugin" && isIframeLoading && (
          <div className={styles.videoLoader}>
            <CircleLoader />
          </div>
        )}
        {data.VideoSource === "facebook_plugin" ? (
          // eslint-disable-next-line jsx-a11y/iframe-has-title
          <iframe
            src={data.VideoURL}
            className={styles.videoIframe}
            onLoad={handleOnIFrameLoad}
          />
        ) : null}
        {data.VideoSource === "instagram_raw" ? (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video
            className={styles.video}
            src={data.VideoURL}
            controls
            autoPlay
          />
        ) : null}
      </div>
    </section>
  )
}

export default VideoPreviewModal
