import React, { useCallback, useEffect, useState } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import moment from "moment"

import Stack from "@components/ui/Stack/Stack"
import { metricsDescriptors } from "@framework/constants/metrics"
import useToggle from "@framework/hooks/useToggle"
import SettingsModal from "@components/modals/FacebookReportWinnersSettingsModal/FacebookReportWinnersSettingsModal"
import {
  AnalyticsCard,
  filterEqualityMapper,
  metricOptions,
} from "@framework/types/creativeAnalytics"
import WinnersControlPanel from "@pages/MetaAdsChannel/Winners/WinnersControlPanel"
import {
  MetricDescription,
  MetricsTrendType,
  MetricType,
} from "@framework/types/metrics"
import { useStore } from "@root/store"
import KPITrendModal from "@pages/MetaAdsChannel/components/KPITrendChart/KPITrendModal"
import VideoPreviewModal from "@pages/MetaAdsChannel/components/Modals/VIdeoPreviewModal"
import {
  ADSAnalyticsGroupTypeEntity,
  ADSAnalyticsResponse,
  WinnersLosersMetricsEntity,
  WinnersLosersUpdateEntity,
} from "@services/creative-analytics"
import { useAlert } from "react-alert"
import useOptionSearchList from "@framework/prototypes/useOptionsSearchList"
import { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import { apiDateFormatter } from "@services/utils"
import { DateRangeOption } from "@components/ui/DatePicker/types"
import { calculateMetricsTrend } from "@pages/MetaAdsChannel/components/utils"
import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import { Button } from "@components/ui/Button"
import Icon from "@components/ui/Icon/Icon"
import SimpleFiltersMultiselect from "@components/ui/FiltersDropDown/SimpleFiltersMultiselect"
import Chip from "@components/ui/Chip/Chip"
import Loader from "@components/ui/Loader/Loader"
import GroupsSidebar from "@pages/MetaAdsChannel/components/GroupsSIdebar/GroupsSidebar"
import GroupCard from "@pages/MetaAdsChannel/components/ReportCard/GroupCard"
import ReportDetailsModal from "../components/ReportDetails/ReportDetails"
import ReportCard from "../components/ReportCard/ReportCard"
import { MetaCampaignStatus } from "../types"
import Table from "../components/Table/Table"
import mapper from "../General/mapper"
import PerformanceGoalCard, {
  FilterType,
  PerformanceGoalCardProps,
} from "../components/PerformanceGoalCard/PerformanceGoalCard"

import styles from "./Winners.module.scss"

type WinnersPageProps = {
  className?: string
}
export interface WinnersLosersSettings {
  winnersFilters: WinnersLosersMetricsEntity[]
  losersFilters: WinnersLosersMetricsEntity[]
  winnersAds: AnalyticsCard[]
  losersAds: AnalyticsCard[]
  scalingAds: AnalyticsCard[]
  newAds: AnalyticsCard[]
  scaling_threshold: number
  new_period: number
  new_period_unit: "days" | "weeks" | "months"
  winnersAmount: number
  losersAmount: number
  scalingAmount: number
  newAmount: number
}

const metricsOptionsArray = Object.entries(metricOptions).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
)
const WinnersPage: React.FC<WinnersPageProps> = observer(({ className }) => {
  const {
    accountStore: { accountId },
    analyticsFiltersStore: {
      activePeriodicity,
      allFilters,
      setActivePeriodicity,
      setAllFilters,
    },
    creativeAnalyticsStore: {
      adsCardsData,
      KPITrendsData,
      winnersLosersData,
      cleanWinnersLosersData,
      getKPITrends,
      getWinnersLosersSettings,
      updateWinnersLosersSettings,
      isLoading,
      isADSCardsLoading,
      isKPILoading,
    },
  } = useStore()
  const { view, groupingType, period, sortColorMetrics, sortByMetric } =
    allFilters

  const [activeGoal, setActiveGoal] = React.useState<FilterType>("winners")

  // NEW HANDLERS
  const alert = useAlert()

  const [filteredCards, setFilteredCards] = React.useState<AnalyticsCard[]>(
    adsCardsData.AdCreatives
  )
  const [filteredGroups, setFilteredGroups] = useState<
    ADSAnalyticsResponse["data"]["Groupings"]
  >(adsCardsData.Groupings || [])

  const [activeMetrics, setActiveMetrics] = useState<MetricDescription[]>([])
  const [report, setReport] = useState<{
    id: string
    name: string
    status: MetaCampaignStatus
  } | null>(null)
  const [showColorMetricsSelect, setShowColorMetricsSelect] = useState(false)
  const [reportDetails, setReportDetails] = useState<AnalyticsCard>()
  const [activeGroup, setActiveGroup] =
    useState<ADSAnalyticsGroupTypeEntity | null>(null)
  const [activeGroupAds, setActiveGroupAds] = useState<AnalyticsCard[]>([])
  const [metricsTrend, setMetricsTrend] = useState<MetricsTrendType>({})

  const [winnersLosers, setWinnersLosers] = useState<WinnersLosersSettings>({
    winnersFilters: [],
    losersFilters: [],
    winnersAds: [],
    losersAds: [],
    scalingAds: [],
    newAds: [],
    scaling_threshold: 0,
    new_period: 0,
    new_period_unit: "days",
    winnersAmount: 0,
    losersAmount: 0,
    scalingAmount: 0,
    newAmount: 0,
  })

  const [winnersFilter, setWinnersFilter] = useState<
    WinnersLosersSettings["winnersFilters"] | []
  >([])

  const [losersFilter, setLosersFilter] = useState<
    WinnersLosersSettings["losersFilters"] | []
  >([])

  const [scalingFilter, setScalingFilter] =
    useState<WinnersLosersSettings["scaling_threshold"]>(0)

  const [newPeriodFilter, setNewPeriodFilter] = useState<{
    new_period: number
    new_period_unit: "days" | "weeks" | "months"
  }>({
    new_period: 0,
    new_period_unit: "days",
  })

  const modal = useToggle()
  const settingsModal = useToggle()
  const KPImodal = useToggle()
  const videoPreviewModal = useToggle()
  const [isGroupsSidebarOpen, setIsGroupsSidebarOpen] = useState(false)

  const metricsOptions = useOptionSearchList({
    list: metricsOptionsArray ?? [],
  })

  const handleFormSubmit = async (values: WinnersLosersUpdateEntity) => {
    if (!accountId || !winnersLosersData) return
    await updateWinnersLosersSettings(
      accountId,
      winnersLosersData.id,
      values
    ).then((res) => {
      if (res) alert.error(res)
      if (!res && !accountId) return
      getWinnersLosersSettings(accountId).then((res) => {
        if (res) alert.error(res)
      })
    })
  }

  const onMetricsFilterChange = (metrics: string[]) => {
    const filteredMetricsDescriptors = metricsDescriptors.filter((md) =>
      metrics.includes(md.name)
    )
    setActiveMetrics(filteredMetricsDescriptors)
  }

  const handleOpenKPItrendModal = (
    id: string,
    name: string,
    status: MetaCampaignStatus
  ) => {
    setReport({ id, name, status: status || "INACTIVE" })
    KPImodal.setOpened(true)
  }

  const handleOpenReportDetailsModal = (card: AnalyticsCard) => {
    setReportDetails(card)
    modal.handleToggle()
  }

  const handleActivePeriodicity = (
    periodicity: PerformanceReportPeriodicity
  ) => {
    setActivePeriodicity(periodicity)
  }

  const handleMetricsOptions = (value: string) => {
    let colorMetrics = [...allFilters.sortColorMetrics]
    if (colorMetrics.find((it) => it === value)) {
      colorMetrics = colorMetrics.filter((it) => it !== value)
    } else {
      colorMetrics = [...colorMetrics, value]
    }
    setAllFilters({
      ...allFilters,
      sortColorMetrics: [...colorMetrics],
    })

    const newMetric: MetricDescription = metricsDescriptors.find(
      (md) => md.name === value
    ) as MetricDescription
    let filteredMetricsDescriptors = metricsDescriptors.filter((md) =>
      activeMetrics.includes(md)
    )
    if (!filteredMetricsDescriptors.find((it) => it.name === newMetric?.name)) {
      filteredMetricsDescriptors = [...filteredMetricsDescriptors, newMetric]
    }
    setActiveMetrics(filteredMetricsDescriptors)
  }

  const handleRemoveColorMetric = (filter: string) => {
    const colorMetrics = [...allFilters.sortColorMetrics].filter(
      (it) => it !== filter
    )
    setAllFilters({
      ...allFilters,
      sortColorMetrics: [...colorMetrics],
    })
  }

  const handleOpenVideoPreview = (card: AnalyticsCard) => {
    setReportDetails(card)
    videoPreviewModal.setOpened(true)
  }

  const handlePreviousDetailsCard = (cardId: string) => {
    if (groupingType === "None") {
      const currentIndex = filteredCards.findIndex(
        (it: AnalyticsCard) => it.Id === cardId
      )
      let prevoriusCard: AnalyticsCard = filteredCards[currentIndex - 1]
      if (currentIndex === 0) {
        prevoriusCard = filteredCards[filteredCards.length - 1]
      }
      setReportDetails(prevoriusCard)
    } else {
      const cardsFilteredByCurrentActiveGroup = filteredCards.filter((card) =>
        activeGroup?.Ads.includes(card.Id)
      )
      if (cardsFilteredByCurrentActiveGroup.length === 1) return
      const currentIndex = cardsFilteredByCurrentActiveGroup.findIndex(
        (it: AnalyticsCard) => it.Id === cardId
      )
      let prevoriusCard: AnalyticsCard =
        cardsFilteredByCurrentActiveGroup[currentIndex - 1]
      if (currentIndex === 0) {
        prevoriusCard =
          cardsFilteredByCurrentActiveGroup[
            cardsFilteredByCurrentActiveGroup.length - 1
          ]
      }
      setReportDetails(prevoriusCard)
    }
  }

  const handleNextDetailsCard = (cardId: string) => {
    if (groupingType === "None") {
      const currentIndex = filteredCards.findIndex(
        (it: AnalyticsCard) => it.Id === cardId
      )
      let nextCard: AnalyticsCard = filteredCards[currentIndex + 1]
      if (currentIndex === filteredCards.length - 1) {
        // eslint-disable-next-line prefer-destructuring
        nextCard = filteredCards[0]
      }
      setReportDetails(nextCard)
    } else {
      const cardsFilteredByCurrentActiveGroup = filteredCards.filter((card) =>
        activeGroup?.Ads.includes(card.Id)
      )
      if (cardsFilteredByCurrentActiveGroup.length === 1) return
      const currentIndex = cardsFilteredByCurrentActiveGroup.findIndex(
        (it: AnalyticsCard) => it.Id === cardId
      )
      let nextCard: AnalyticsCard =
        cardsFilteredByCurrentActiveGroup[currentIndex + 1]
      if (currentIndex === cardsFilteredByCurrentActiveGroup.length - 1) {
        // eslint-disable-next-line prefer-destructuring
        nextCard = cardsFilteredByCurrentActiveGroup[0]
      }
      setReportDetails(nextCard)
    }
  }

  const calculateTotalCardsWithinGroup = useCallback(
    (group: ADSAnalyticsGroupTypeEntity) => {
      const cards = filteredCards.filter((card) => group.Ads.includes(card.Id))
      return cards?.length || 0
    },
    [filteredCards]
  )

  const handleGroupClick = (group: ADSAnalyticsGroupTypeEntity) => {
    setActiveGroup(group)
    setIsGroupsSidebarOpen(true)
    const groupAds = filteredCards.filter((card) =>
      group?.Ads.includes(card.Id)
    )
    setActiveGroupAds(groupAds)
  }

  useEffect(() => {
    setWinnersFilter(winnersLosers.winnersFilters)
    setLosersFilter(winnersLosers.losersFilters)
    setScalingFilter(winnersLosers.scaling_threshold)
    setNewPeriodFilter({
      new_period: winnersLosersData?.settings?.new_period || 0,
      new_period_unit: winnersLosersData?.settings?.new_period_unit || "days",
    })
    const winLosColorMetrics = new Set<string>()
    winnersLosersData?.settings?.metric_criteria.forEach((mc) =>
      winLosColorMetrics.add(mc.metric_name)
    )
    const colorMetrics = Array.from(winLosColorMetrics)
    setAllFilters({ ...allFilters, sortColorMetrics: colorMetrics })
  }, [activeGoal, winnersLosersData, winnersLosers, adsCardsData])

  useEffect(() => {
    const newPeriod: DateRangeOption = {
      label: "",
      value: "",
      range: period.range,
    }
    setAllFilters({
      view: "card",
      filters: [],
      groupingType: "None",
      sortByMetric: null,
      period: newPeriod,
      sortColorMetrics: [],
    })
  }, [adsCardsData])

  useEffect(() => {
    const res = calculateMetricsTrend(
      adsCardsData.AdCreatives,
      sortColorMetrics
    )
    setMetricsTrend(res)
  }, [adsCardsData, sortColorMetrics])

  useEffect(() => {
    setFilteredCards(adsCardsData.AdCreatives)
    setFilteredGroups(adsCardsData.Groupings)
  }, [adsCardsData])

  useEffect(() => {
    if (!accountId || !report) return
    getKPITrends(accountId, report.id, {
      from: apiDateFormatter(period.range[0]),
      to: apiDateFormatter(period.range[1]),
      periodicity: activePeriodicity,
    }).then((res) => {
      if (res) alert.error(res)
    })
  }, [report, activePeriodicity, allFilters])

  useEffect(() => {
    if (!accountId) return
    getWinnersLosersSettings(accountId).then((res) => {
      if (res) alert.error(res)
    })
  }, [accountId])

  useEffect(() => {
    const winners =
      winnersLosersData?.settings?.metric_criteria?.filter(
        (it) => it.category === "Winners"
      ) || []
    const losers =
      winnersLosersData?.settings?.metric_criteria?.filter(
        (it) => it.category === "Losers"
      ) || []

    let winnersAds: AnalyticsCard[] = []
    if (winners.length) {
      winnersAds = adsCardsData.AdCreatives
      winners.forEach((f) => {
        const filterEqualityMapperType = filterEqualityMapper(f.operator)
        winnersAds = winnersAds.filter((it) => {
          const metric = it[f.metric_name] as MetricType
          if (typeof metric === "object") {
            return filterEqualityMapperType(metric.Value, f.value1)
          }
          return false
        })
      })
    }

    let losersAds: AnalyticsCard[] = []
    if (losers.length) {
      losersAds = adsCardsData.AdCreatives
      losers.forEach((f) => {
        const filterEqualityMapperType = filterEqualityMapper(f.operator)
        losersAds = losersAds.filter((it) => {
          const metric = it[f.metric_name] as MetricType
          if (typeof metric === "object") {
            return filterEqualityMapperType(metric.Value, f.value1)
          }
          return false
        })
      })
    }

    let scalingAds: AnalyticsCard[] = []
    scalingAds = adsCardsData.AdCreatives.filter((it) => {
      const metric = it.Cost as MetricType
      if (typeof metric === "object") {
        return metric.Value >= scalingFilter
      }
      return false
    })

    let newAds: AnalyticsCard[] = []
    const now = moment()
    newAds = adsCardsData.AdCreatives.filter((ad) => {
      if (!ad.CreatedAt) return false

      const adDate = moment(ad.CreatedAt)
      return adDate.isAfter(
        now
          .clone()
          .subtract(
            winnersLosersData?.settings?.new_period || 0,
            winnersLosersData?.settings?.new_period_unit || "days"
          )
      )
    })

    if (adsCardsData && winnersLosersData) {
      setWinnersLosers({
        winnersFilters: winners,
        losersFilters: losers,
        winnersAds,
        losersAds,
        scalingAds,
        newAds,
        scaling_threshold: winnersLosersData?.settings?.scaling_threshold || 0,
        new_period: winnersLosersData?.settings?.new_period || 0,
        new_period_unit: winnersLosersData?.settings?.new_period_unit || "days",
        winnersAmount: winnersAds.length,
        losersAmount: losersAds.length,
        scalingAmount: scalingAds.length,
        newAmount: newAds.length,
      })
    }
  }, [winnersLosersData, adsCardsData, scalingFilter])

  useEffect(() => {
    let result: AnalyticsCard[] = adsCardsData.AdCreatives

    let resultGroups =
      groupingType !== "None" ? adsCardsData.Groupings[groupingType] : []

    if (activeGoal === "winners" && winnersFilter.length) {
      result = winnersLosers.winnersAds
      winnersFilter.forEach((f) => {
        const filterEqualityMapperType = filterEqualityMapper(f.operator)
        resultGroups = resultGroups.filter((it) => {
          // @ts-ignore
          const metric = it.Performance[f.metric_name] as MetricType
          if (typeof metric === "object") {
            return filterEqualityMapperType(metric.Value, f.value1)
          }
          return false
        })
      })
    }
    if (activeGoal === "losers" && losersFilter.length) {
      result = winnersLosers.losersAds
      losersFilter.forEach((f) => {
        const filterEqualityMapperType = filterEqualityMapper(f.operator)
        resultGroups = resultGroups.filter((it) => {
          // @ts-ignore
          const metric = it.Performance[f.metric_name] as MetricType
          if (typeof metric === "object") {
            return filterEqualityMapperType(metric.Value, f.value1)
          }
          return false
        })
      })
    }
    if (activeGoal === "scaling") {
      result = winnersLosers.scalingAds
      resultGroups = resultGroups.filter((it) => {
        const metric = it.Performance.Cost as MetricType
        if (typeof metric === "object") {
          return metric.Value >= scalingFilter
        }
        return false
      })
    }
    if (activeGoal === "new") {
      result = winnersLosers.newAds
      // TODO: check for the "createdAt" prop from the API (currently unavailable)
      resultGroups =
        groupingType !== "None" ? adsCardsData.Groupings[groupingType] : []
    }
    // Check for active sorting
    if (sortByMetric?.value && sortByMetric?.order) {
      const cards = [...result]
      const groups: ADSAnalyticsGroupTypeEntity[] =
        groupingType !== "None" && filteredGroups ? [...resultGroups] : []

      cards.sort((a, b) => {
        const aMetric = a[sortByMetric.value] as MetricType
        const bMetric = b[sortByMetric.value] as MetricType
        const order = sortByMetric.order === "asc" ? 1 : -1
        return (aMetric.Value - bMetric.Value) * order
      })

      groups.sort((a, b) => {
        // @ts-ignore
        const aMetric = a.Performance?.[sortByMetric.value] as MetricType
        // @ts-ignore
        const bMetric = b.Performance?.[sortByMetric.value] as MetricType
        const order = sortByMetric.order === "asc" ? 1 : -1
        return (aMetric.Value - bMetric.Value) * order
      })

      setFilteredGroups((prevState) => ({
        ...prevState,
        [groupingType]: groups,
      }))
      setFilteredCards(cards)
    } else {
      setFilteredCards(result)
      if (groupingType === "None") {
        setFilteredGroups(adsCardsData.Groupings)
      } else {
        setFilteredGroups((prevState) => ({
          ...prevState,
          [groupingType]: resultGroups,
        }))
      }
    }
  }, [
    adsCardsData,
    allFilters,
    winnersLosersData,
    activeGoal,
    winnersFilter,
    losersFilter,
    scalingFilter,
    newPeriodFilter,
  ])

  useEffect(
    () => () => {
      setWinnersLosers({
        winnersFilters: [],
        losersFilters: [],
        winnersAds: [],
        losersAds: [],
        scalingAds: [],
        newAds: [],
        scaling_threshold: 0,
        new_period: 0,
        new_period_unit: "days",
        winnersAmount: 0,
        losersAmount: 0,
        scalingAmount: 0,
        newAmount: 0,
      })
      cleanWinnersLosersData()
    },
    []
  )

  return (
    <div className={clsx(styles.root, className)}>
      <Stack direction="column" gutter="20" align="stretch">
        <WinnersControlPanel
          activeMetrics={activeMetrics.map((metric) => metric.name)}
          onMetricsFilter={onMetricsFilterChange}
        />

        <Box>
          <Typography type="body2" weight="bold">
            Color sorting by
          </Typography>
          <div className={styles.colorMetricsPanel}>
            <Button
              color="primary"
              variant="outlined"
              before={<Icon name="plus" />}
              onClick={() => setShowColorMetricsSelect((prev) => !prev)}
            >
              Add metrics
            </Button>
            {showColorMetricsSelect && (
              <SimpleFiltersMultiselect
                className={styles.colorMetricsSelect}
                withSearch
                inputClassName={styles.colorMetricsSelectInput}
                onSelect={handleMetricsOptions}
                value={sortColorMetrics}
                placeholder="Add metric"
                onClickOutside={() => setShowColorMetricsSelect(false)}
                {...metricsOptions}
              />
            )}
            {sortColorMetrics.map((filter) => {
              if (!filter) return null
              return (
                <Chip
                  type="contained"
                  color="secondary"
                  endIcon={
                    <Icon
                      name="cross"
                      onClick={() => handleRemoveColorMetric(filter)}
                    />
                  }
                  className={styles.filterChip}
                  key={filter}
                >
                  {filter}
                </Chip>
              )
            })}
          </div>
        </Box>

        {!isADSCardsLoading && (
          <div className={styles.goalsGrid}>
            {performanceGoalOptions.map((item, idx) => {
              let total = 0
              switch (item.filterType) {
                case "winners":
                  total = winnersLosers.winnersAmount
                  break
                case "losers":
                  total = winnersLosers.losersAmount
                  break
                case "scaling":
                  total = winnersLosers.scalingAmount
                  break
                case "new":
                  total = winnersLosers.newAmount
                  break
                default:
              }
              return (
                <PerformanceGoalCard
                  {...item}
                  total={total}
                  active={activeGoal === item.filterType}
                  onClick={() => setActiveGoal(item.filterType)}
                  onEditClick={() => settingsModal.setOpened(true)}
                  key={item.title}
                />
              )
            })}
          </div>
        )}

        {(isADSCardsLoading || isLoading) && <Loader />}

        {view === "table" && !isADSCardsLoading && (
          <Table
            className={styles.table}
            mapper={Object.values(mapper(activeMetrics, metricsTrend))}
            data={filteredCards}
            summary={adsCardsData.Summary}
            metricsTrend={metricsTrend}
            onRowClick={handleOpenReportDetailsModal}
          />
        )}

        <div className={styles.grid}>
          {view === "card" &&
            groupingType === "None" &&
            !isADSCardsLoading &&
            filteredCards.map((card) => (
              <ReportCard
                onOpenVideoPreview={handleOpenVideoPreview}
                data={card}
                metricsTrend={metricsTrend}
                metrics={activeMetrics}
                onClick={(event) => {
                  event.stopPropagation()
                  handleOpenReportDetailsModal(card)
                }}
                onOpenKPIChart={handleOpenKPItrendModal}
                key={card.Id}
              />
            ))}
        </div>

        <div className={styles.gridGroups}>
          {!isLoading &&
          !isADSCardsLoading &&
          filteredGroups &&
          groupingType !== "None" &&
          view !== "table"
            ? filteredGroups[groupingType].map(
                (group: ADSAnalyticsGroupTypeEntity) => {
                  const cards =
                    filteredCards.filter((card) =>
                      group.Ads.includes(card.Id)
                    ) || []
                  return (
                    <GroupCard
                      adsCard={cards[0]}
                      groupingType={groupingType}
                      data={group}
                      metricsTrend={metricsTrend}
                      metrics={activeMetrics}
                      total={calculateTotalCardsWithinGroup(group)}
                      onClick={() => handleGroupClick(group)}
                      onOpenVideoPreview={handleOpenVideoPreview}
                    />
                  )
                }
              )
            : null}
        </div>
      </Stack>

      {settingsModal.isOpened && winnersLosersData ? (
        <SettingsModal
          isOpen
          onSubmit={handleFormSubmit}
          onClose={() => settingsModal.setOpened(false)}
          data={winnersLosers}
          isLoading={isLoading}
        />
      ) : null}

      <ReportDetailsModal
        open={modal.isOpened}
        onClose={() => modal.setOpened(false)}
        reportDetails={reportDetails as AnalyticsCard}
        metricsTrend={metricsTrend}
        onPreviousClick={handlePreviousDetailsCard}
        onNextClick={handleNextDetailsCard}
      />

      <KPITrendModal
        activePeriodicity={activePeriodicity}
        onActivePeriodicity={handleActivePeriodicity}
        report={report}
        data={KPITrendsData}
        open={KPImodal.isOpened}
        isKPILoading={isKPILoading}
        onClose={() => KPImodal.setOpened(false)}
      />

      <GroupsSidebar
        period={period}
        metricsTrend={metricsTrend}
        activeGroupAds={activeGroupAds}
        activeGroup={activeGroup}
        activeMetrics={activeMetrics}
        adsCardsData={adsCardsData}
        isOpen={isGroupsSidebarOpen}
        onClose={() => setIsGroupsSidebarOpen(false)}
        onOpenKPItrendModal={handleOpenKPItrendModal}
        onOpenDetailsModal={(card) => {
          handleOpenReportDetailsModal(card)
        }}
        onVideoPreviewOpen={(card) => {
          handleOpenVideoPreview(card)
        }}
      />

      {videoPreviewModal.isOpened && reportDetails ? (
        <VideoPreviewModal
          data={reportDetails}
          onDetailsOpen={() => {
            modal.setOpened(true)
            videoPreviewModal.setOpened(false)
          }}
          onClose={() => videoPreviewModal.setOpened(false)}
        />
      ) : null}
    </div>
  )
})

export default WinnersPage

const performanceGoalOptions: Omit<PerformanceGoalCardProps, "total">[] = [
  {
    title: "Winners",
    description: "Creatives, that reached your goal within the time period",
    status: "winner",
    filterType: "winners",
  },
  {
    title: "Losers",
    description:
      "Creatives, that have not reached your goal within the time period",
    status: "loser",
    filterType: "losers",
  },
  {
    title: "Scaling",
    description: "Creatives, that spend more of your goal",
    filterType: "scaling",
  },
  {
    title: "New",
    description: "Creatives, that launched within the specific period",
    filterType: "new",
  },
]
