import { ITypographyProps } from "@components/ui/Typography/Typography"

export type TrendDirection = "up" | "down" | "none"

export type MetricDirection = {
  direction: TrendDirection
  color?: ITypographyProps["color"]
}

export const metricNamesList = [
  "AOV",
  "AverageCpc",
  "Clicks",
  "ConversionRate",
  "Conversions",
  "Cost",
  "Ctr",
  "Impressions",
  "ResClickRate",
  "Revenue",
  "Roas",
  "CostConv",
  "SearchImpressionShare",
  "Cpm",
  "Thumbstop",
  "Holdplay",
] as const

export const metricNamesListV1 = [
  "AOV",
  "Clicks",
  "Impressions",
  "Conversions",
  "CostConv",
  "ConversionRate",
  "Revenue",
  "Ctr",
  "AverageCpc",
  "Cpm",
  "Roas",
  "Cost",
  "Thumbstop",
  "Holdplay",
] as const

export const SummaryMetricList = ["OverallKPIs", "KPIChart", "TopAds"]

export type MetricNameType = (typeof metricNamesList)[number]
export type MetricNameTypeV1 = (typeof metricNamesListV1)[number]
export type SummaryMetricNameType = (typeof SummaryMetricList)[number]
export interface MetricDescription {
  /**
   * Metric name/key
   */
  name: MetricNameType

  /**
   * Label to show in UI
   */
  label: string

  /**
   * Short version of label to show in UI
   */
  shortLabel: string

  /**
   * Some description of metric
   */
  description: string

  /**
   * kpi trend direction format
   */
  trendFormat?: (value: number) => MetricDirection

  trendDirection?: "reversed" | "normal"

  /**
   * function to normalize and print value
   * @param value Number value that will be converted to readable format
   * @returns string value to print in UI
   */
  formatter: (value: number | string) => string

  normalizeForUI?: (value: number | string) => number

  normalizeForAPI?: (value: number | string) => number

  /**
   * to use in form field
   */
  unit?: {
    decimalScale?: number
    prefix?: string
    suffix?: string
  }
}

export type MetricType = {
  Value: number
  Change?: number // 0 to 1 value
}

export type PerformanceReport = Partial<Record<MetricNameType, MetricType>>
export type PerformanceReportV1 = Partial<Record<MetricNameTypeV1, MetricType>>

export interface TimeSegmentedPerformanceReport {
  Metrics: PerformanceReport
  Period: string // yyyy-mm-dd
}

export type TSPerfReportMap = Record<string, TimeSegmentedPerformanceReport>

export interface PercentageTrendRange {
  ["0 - 10%"]: { min: number; max: number }
  ["10% - 20%"]: { min: number; max: number }
  ["20% - 30%"]: { min: number; max: number }
  ["30% - 40%"]: { min: number; max: number }
  ["40% - 50%"]: { min: number; max: number }
  ["50% - 60%"]: { min: number; max: number }
  ["60% - 70%"]: { min: number; max: number }
  ["70% - 80%"]: { min: number; max: number }
  ["80% - 90%"]: { min: number; max: number }
  ["90% - 100%"]: { min: number; max: number }
}
export type MetricsTrendType = Record<
  string,
  { min: number; max: number; trend: PercentageTrendRange }
>
