import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"

import Stack from "@components/ui/Stack/Stack"
import { Button, ButtonGroup } from "@components/ui/Button"
import Box from "@components/ui/Box/Box"
import Icon from "@components/ui/Icon/Icon"
import ButtonDatePicker from "@components/ui/DatePicker/ButtonDatePicker"
import IconButton from "@components/ui/Button/IconButton"
import useOptionSearchList from "@framework/prototypes/useOptionsSearchList"
import { DateRangeOption } from "@components/ui/DatePicker/types"
import { useStore } from "@root/store"
import { apiDateFormatter } from "@services/utils"
import {
  defaultMetrics,
  groupByObjectOptions,
  metricOptions,
} from "@framework/types/creativeAnalytics"
import { ADSAnalyticsGroupType } from "@services/creative-analytics"
import { useAlert } from "react-alert"
import SimpleFilterSelect from "@components/ui/FiltersDropDown/SimpleFilterSelect"
import SimpleFiltersMultiselect from "@components/ui/FiltersDropDown/SimpleFiltersMultiselect"
import styles from "./Winners.module.scss"

interface WinnersControlPanelProps {
  onMetricsFilter?: (metrics: string[]) => void
  activeMetrics: string[]
}

const metricsOptionsArray = Object.entries(metricOptions).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
)

const metricsSortByOptionsArray: { value: string; label: string }[] = []
Object.entries(metricOptions).forEach(([key, value]) => {
  metricsSortByOptionsArray.push({
    value: key,
    label: value,
  })
})

const groupByOptionsArray = Object.entries(groupByObjectOptions).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
)

const WinnersControlPanel: React.FC<WinnersControlPanelProps> = observer(
  ({ onMetricsFilter, activeMetrics }) => {
    const {
      accountStore: { accountId },
      creativeAnalyticsStore: { getADSCards, adsCardsData, isLoading },
      analyticsFiltersStore: { datePickerOptions, allFilters, setAllFilters },
    } = useStore()

    const alert = useAlert()

    const { view, groupingType, period } = allFilters
    const [showMetrics, setShowMetrics] = useState(false)
    const [showGroupBy, setShowGroupBy] = useState(false)
    const [showSortBy, setShowSortBy] = useState(false)
    const [metricSortByValue, setMetricSortByValue] = useState<string>("Cost")
    const [groupByValue, setGroupByValue] = useState<
      ADSAnalyticsGroupType | "None"
    >(
      groupByOptionsArray.find((opt) => opt.value === groupingType)
        ? groupingType
        : "None"
    )
    const [metricOptionsValues, setMetricOptionsValues] = useState<string[]>(
      activeMetrics || defaultMetrics
    )

    const metricsOptions = useOptionSearchList({
      list: metricsOptionsArray ?? [],
    })

    const groupByOptions = useOptionSearchList({
      list: groupByOptionsArray ?? [],
    })

    const metricsSortByOptions = useOptionSearchList({
      list: metricsSortByOptionsArray ?? [],
    })

    useEffect(() => {
      setGroupByValue("None")
      setMetricOptionsValues(defaultMetrics)
    }, [adsCardsData])

    useEffect(() => {
      if (onMetricsFilter) onMetricsFilter(metricOptionsValues)
    }, [metricOptionsValues])

    useEffect(() => {
      if (onMetricsFilter) onMetricsFilter(metricOptionsValues)
    }, [])

    useEffect(() => {
      setAllFilters({
        ...allFilters,
        sortByMetric: { value: "Cost", order: "desc" },
      })
    }, [])

    const handleGroupBy = (value: ADSAnalyticsGroupType | "None") => {
      if (view === "table") {
        setAllFilters({ ...allFilters, view: "card", groupingType: value })
      } else {
        setAllFilters({ ...allFilters, groupingType: value })
      }
      setGroupByValue(value)
    }

    const handleMetricsOptions = (value: string) => {
      if (activeMetrics) {
        const am = new Set([...activeMetrics, ...metricOptionsValues])
        let res = Array.from(am)
        if (res.includes(value)) {
          res = res.filter((it) => it !== value)
        } else {
          res.push(value)
        }
        setMetricOptionsValues(res)
      } else {
        setMetricOptionsValues((prev) =>
          prev.includes(value)
            ? prev.filter((it) => it !== value)
            : [...prev, value]
        )
      }
    }

    const handleSetPeriod = (period: DateRangeOption) => {
      setAllFilters({ ...allFilters, period })
      if (!accountId) return
      getADSCards(accountId, {
        from: apiDateFormatter(period.range[0]),
        to: apiDateFormatter(period.range[1]),
      }).then((res) => {
        if (res) alert.error(res)
      })
    }

    const handleSortByMetricsOrder = (value: string, order: "asc" | "desc") => {
      setAllFilters({
        ...allFilters,
        sortByMetric: { value, order },
      })
    }

    const handleSortByMetrics = (value: string) => {
      setMetricSortByValue(value)
      setAllFilters({
        ...allFilters,
        sortByMetric: { value, order: "desc" },
      })
    }

    if (isLoading) return <div />

    return (
      <Box>
        <Stack
          className={styles.reportControlPanel}
          direction="row"
          gutter="8"
          justify="space-between"
        >
          <Stack direction="row" gutter="8">
            {/* <Button color="secondary" before={<Icon name="refresh" />}> */}
            {/*  Sync */}
            {/* </Button> */}
            <ButtonDatePicker
              range={period}
              onRangeChange={handleSetPeriod}
              rangeOptions={datePickerOptions}
            />
            <div className={styles.filterButton}>
              <Button
                color="secondary"
                before={<Icon name="stack" />}
                onClick={() => setShowGroupBy((prev) => !prev)}
                disabled={view === "table"}
              >
                {groupByValue === "None"
                  ? "Group"
                  : groupByObjectOptions[groupByValue]}
              </Button>
              {showGroupBy && (
                <div className={styles.groupByMenu}>
                  <SimpleFilterSelect
                    className={styles.select}
                    onSelect={(value) =>
                      handleGroupBy(value as ADSAnalyticsGroupType)
                    }
                    onClickOutside={() => setShowGroupBy(false)}
                    value={groupByValue}
                    {...groupByOptions}
                  />
                </div>
              )}
            </div>
            <div className={styles.filterButton}>
              <Button
                color="secondary"
                before={<Icon name="eye" />}
                onClick={() => setShowMetrics((prev) => !prev)}
              >
                Metrics
              </Button>
              {showMetrics && (
                <div className={styles.metricsMenu}>
                  <SimpleFiltersMultiselect
                    className={styles.select}
                    inputClassName={styles.selectInput}
                    withSearch
                    onSelect={handleMetricsOptions}
                    value={Array.from(
                      new Set([...metricOptionsValues, ...activeMetrics])
                    )}
                    placeholder="Search metrics"
                    onClickOutside={() => setShowMetrics(false)}
                    {...metricsOptions}
                  />
                </div>
              )}
            </div>
            <div className={styles.filterButton}>
              <ButtonGroup>
                <IconButton
                  disabled={view === "table"}
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    handleSortByMetricsOrder(
                      metricSortByValue,
                      allFilters?.sortByMetric?.order === "asc" ? "desc" : "asc"
                    )
                  }
                >
                  <Icon
                    name="sort-desc"
                    rotateAngle={
                      allFilters?.sortByMetric?.order === "desc" ? 0 : 180
                    }
                  />
                </IconButton>
                <Button
                  color="secondary"
                  onClick={() => setShowSortBy((prev) => !prev)}
                  disabled={view === "table"}
                >
                  {metricSortByValue}
                </Button>
              </ButtonGroup>
              {showSortBy && (
                <div className={styles.sortByMenu}>
                  <SimpleFilterSelect
                    className={styles.select}
                    inputClassName={styles.selectInput}
                    withSearch
                    placeholder="Search metrics"
                    onSelect={handleSortByMetrics}
                    onClickOutside={() => setShowSortBy(false)}
                    value={metricSortByValue}
                    {...metricsSortByOptions}
                  />
                </div>
              )}
            </div>
          </Stack>

          <Stack direction="row" gutter="8" justify="flex-end">
            <ButtonGroup>
              <IconButton
                variant="contained"
                color={view === "card" ? "primary" : "secondary"}
                onClick={() => {
                  setAllFilters({ ...allFilters, view: "card" })
                }}
              >
                <Icon name="dashboard1" />
              </IconButton>
              <IconButton
                variant="contained"
                color={view === "table" ? "primary" : "secondary"}
                onClick={() => {
                  setAllFilters({ ...allFilters, view: "table" })
                }}
              >
                <Icon name="menu" />
              </IconButton>
            </ButtonGroup>
          </Stack>
        </Stack>
      </Box>
    )
  }
)

export default WinnersControlPanel
