import React from "react"
import clsx from "clsx"
import get from "lodash/get"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Box from "@components/ui/Box/Box"
import { MetricsTrendType, MetricType } from "@framework/types/metrics"
import NoData from "@components/ui/NoData/NoData"
import ScrollContainer from "@components/ui/ScrollContainer/ScrollContainer"
import {
  AnalyticsCard,
  defaultMetrics,
  DropOffHistogramEntity,
} from "@framework/types/creativeAnalytics"
import { metricsDescriptors } from "@framework/constants/metrics"
import { cutString } from "@utils/stringUtils"
import CircleLoader from "@components/ui/Loader/CircleLoader"
import AreaChart from "@components/ui/Charts/AreaChart/AreaChart"
import StatusBadge from "@pages/MetaAdsChannel/components/StatusBadge"
import MetricCard from "./MetricCard"

import styles from "./DetailsSection.module.sass"

interface DetailsSectionProps {
  data: AnalyticsCard
  metricsTrend: MetricsTrendType
  dropOffHistogramData: DropOffHistogramEntity[]
  isLoading: boolean
}
const filteredDefaultMetricsDescriptors = metricsDescriptors.filter((md) =>
  defaultMetrics.includes(md.name)
)
const filteredMetricsDescriptors = metricsDescriptors.filter(
  (md) => !defaultMetrics.includes(md.name)
)

export const DetailsSection: React.FC<DetailsSectionProps> = observer(
  ({ data, metricsTrend, dropOffHistogramData, isLoading }) => {
    const labels = dropOffHistogramData.map((item) => item.Timeframe)
    const points = dropOffHistogramData.map((item) => item.DropOff)
    return (
      <Box className={clsx(styles.root)} elevation={1}>
        <section className={styles.header}>
          <Typography type="h4" color="black100Color">
            Details
          </Typography>
        </section>

        <ScrollContainer className={styles.bodyContainer} type="secondary">
          <section className={styles.body}>
            <Stack direction="column" align="stretch" gutter="8">
              <Typography type="h6" color="black70Color" upperCase>
                General
              </Typography>

              <Stack
                direction="row"
                align="center"
                justify="space-between"
                gutter="0"
                className={styles.nameTitle}
              >
                <Typography type="caption3" color="black70Color">
                  Ad name
                </Typography>
                <Typography type="body2" color="black100Color">
                  {cutString(data?.Name, 35)}
                </Typography>

                <Typography type="body2" className={styles.titleTooltip}>
                  {data?.Name}
                </Typography>
              </Stack>

              <Stack
                direction="row"
                align="center"
                justify="space-between"
                gutter="0"
                className={styles.nameTitle}
              >
                <Typography type="caption3" color="black70Color">
                  Campaign
                </Typography>
                <Typography type="body2" color="black100Color">
                  {cutString(data?.CampaignName, 35)}
                </Typography>

                <Typography type="body2" className={styles.titleTooltip}>
                  {data?.CampaignName}
                </Typography>
              </Stack>

              <Stack
                direction="row"
                align="center"
                justify="space-between"
                gutter="0"
                className={styles.nameTitle}
              >
                <Typography type="caption3" color="black70Color">
                  Ad Set Name
                </Typography>
                <Typography type="body2" color="black100Color">
                  {cutString(data?.AdsetName, 35)}
                </Typography>

                <Typography type="body2" className={styles.titleTooltip}>
                  {data?.AdsetName}
                </Typography>
              </Stack>

              <Stack
                direction="row"
                align="center"
                justify="space-between"
                gutter="0"
                className={styles.nameTitle}
              >
                <Typography type="caption3" color="black70Color">
                  Status
                </Typography>
                <div>
                  <StatusBadge status={data.Status} />
                </div>
              </Stack>

              <Stack
                direction="row"
                align="center"
                justify="space-between"
                gutter="0"
                className={styles.nameTitle}
              >
                <Typography type="caption3" color="black70Color">
                  Creative type
                </Typography>
                <Typography type="body2" color="black100Color">
                  {data?.Type}
                </Typography>
              </Stack>
            </Stack>

            {data?.Type === "VIDEO" && (
              <div className={styles.trendContainer}>
                {isLoading && (
                  <div className={styles.trendLoader}>
                    <CircleLoader />
                  </div>
                )}
                <Stack gutter="4">
                  <Typography type="h4" weight="bold">
                    Drop-off trend
                  </Typography>
                  <Typography type="caption2" color="black60Color">
                    Video duration:{" "}
                    {isLoading
                      ? "Loading..."
                      : Math.max(...labels.map((item) => Number(item)))}
                  </Typography>
                </Stack>

                <div className={styles.chart}>
                  <AreaChart labels={labels} data={points} />
                </div>
              </div>
            )}

            <Stack direction="column" align="stretch" gutter="8">
              <Typography type="h6" color="black70Color" upperCase>
                Core
              </Typography>

              <div className={styles.grid}>
                {filteredDefaultMetricsDescriptors
                  ?.filter((it) => defaultMetrics.includes(it.name))
                  .map((metric) => {
                    const value = get(
                      data,
                      metric.name,
                      null
                    ) as MetricType | null
                    return (
                      <MetricCard
                        key={metric.name}
                        value={value}
                        metric={metric}
                        metricsTrend={metricsTrend}
                        withScale
                      />
                    )
                  }) ?? <NoData />}
              </div>
            </Stack>

            <Stack direction="column" align="stretch" gutter="8">
              <Typography type="h6" color="black70Color" upperCase>
                Analytics
              </Typography>

              <div className={styles.grid}>
                {filteredMetricsDescriptors
                  ?.filter((it) => !defaultMetrics.includes(it.name))
                  .map((metric) => {
                    const value = get(
                      data,
                      metric.name,
                      null
                    ) as MetricType | null
                    return (
                      <MetricCard
                        key={metric.name}
                        value={value}
                        metric={metric}
                        metricsTrend={metricsTrend}
                        withScale
                      />
                    )
                  }) ?? <NoData />}
              </div>
            </Stack>
          </section>
        </ScrollContainer>
      </Box>
    )
  }
)

export default DetailsSection
