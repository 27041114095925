import { ShopifySettingOptions } from "@framework/types/account"
import accountService from "@services/account.service"
import { action, computed, observable } from "mobx"

export interface SettingOptions {
  emailBackInStock: boolean | null
  emailOutOfStock: boolean | null
  metaAdsPauseOutOfStock: boolean | null
  metaAdsStartInStock: boolean | null
}

class ProductSettingsStore {
  @observable settingOptions: SettingOptions = {
    emailBackInStock: null,
    emailOutOfStock: null,
    metaAdsPauseOutOfStock: null,
    metaAdsStartInStock: null,
  }

  constructor(settings?: SettingOptions) {
    if (settings) this.settingOptions = settings
  }

  // computed
  @computed get getSettingOptions(): SettingOptions {
    return this.settingOptions
  }

  // actions
  @action loadSettingOptions = async (accountId: number) => {
    try {
      const response = await accountService.getShopifySettings(accountId)
      if (response.data.data) {
        this.settingOptions = response.data.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action setNewSettings = async (
    accountId: number,
    settings: ShopifySettingOptions
  ) => {
    try {
      const response = await accountService.setShopifySettings(
        accountId,
        settings
      )
      if (response.data.data.ok) {
        this.settingOptions = settings
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export default ProductSettingsStore
