import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useAlert } from "react-alert"

import Loader from "@components/ui/Loader/Loader"

type LinkedAccountsProps = {}

const LinkMetaAccountRedirect: React.FC<LinkedAccountsProps> = observer(() => {
  const history = useHistory()
  const alert = useAlert()
  useEffect(() => {
    try {
      const url = new URL(window.location.href)
      const parsedHash = new URLSearchParams(url.hash.substring(1))
      const state = parsedHash.get("state")
      const access_token = parsedHash.get("access_token")
      const error_message = parsedHash.get("error_message")

      if (!state || error_message) throw new Error("Empty linking state")
      if (!access_token) throw new Error("Access token was not sent")

      history.replace(
        `/account/${state}/manage-profile?alter=linkedAccounts&access_token=${access_token}`
      )
    } catch (err) {
      alert.error(`Failed to link account: ${err}`)
      history.push("/")
    }
  }, [history])

  return <Loader />
})

export default LinkMetaAccountRedirect
