/* eslint-disable camelcase */
import { AxiosResponse } from "axios"
import { ID } from "@framework/types/types"
import { AUTH_TOKEN_KEY } from "@framework/constants/auth"
import config from "@root/config"

import {
  AssignedAttribute,
  KeywordEntity,
} from "@store/opportunities/opportunities.transformer"
import {
  AccountData,
  AdGroupReportDataType,
  IndustryType,
  MonthlySearchVolume,
  PerformanceSegmentType,
  ProductCategoryOptionData,
  ProductFeedInstance,
  ProductFeedStatus,
  ProductImportStats,
  ProductPublishStats,
  ProductTempChangeEntity,
  AdGroupSegmentedPerformanceReport,
  ShopifyStatusUpdate,
  ProductCategoryNodeData,
  AccountGoalSettingsOption,
  ShopifySettingOptions,
  MetaAdsItem,
} from "@framework/types/account"
import { PerformancePeriodReportFilter } from "@framework/types/dashboard"
import {
  TimeSegmentedPerformanceReport,
  PerformanceReport,
  MetricNameType,
  MetricType,
} from "@framework/types/metrics"
import {
  AdCopyEntity,
  AdCopyInstance,
  AdCopyDetailsEntity,
  EditAdCopyData,
  AdCopyPrompts,
} from "@framework/types/adCopy"
import {
  RelatedQuestionData,
  RelatedQuestionSetName,
  TopKeywordEntity,
  TopicData,
} from "@framework/types/opportunities"
import { MetaAccountEntity } from "@store/account-linking/account-linking"
import HttpService from "./http.service"
import { DefaultOkResponse, InfiniteIdList } from "./common"
import { apiDateFormatter } from "./utils"

export interface AccountResponse {
  data: {
    total: number
    records: AccountData[] | null
  }
}

export interface GetAccountGoalsSettingsResponse {
  data: {
    propertyId: number
    settings: AccountGoalSettingsOption[]
  }
}

export interface GetAccountGoalsOptionsResponse {
  data: MetricNameType[] | null
}

export interface GetAccountGoalsMonthlyPerformanceResponse {
  data: Record<MetricNameType, MetricType> | null
}

export interface AccountDataResponse {
  data: AccountData
}

export interface KeywordsResponse {
  data: {
    records: KeywordEntity[]
  }
}
export interface AssignedAttributeResponse {
  data: {
    records: AssignedAttribute[] | null
  }
}

export interface PerformanceReportResponse {
  data: PerformanceReport
}

export interface GetPerformancePeriodReportResponse {
  data: TimeSegmentedPerformanceReport[]
}

export interface GetMonthlySearchReportResponse {
  data: MonthlySearchVolume[]
}

export type PerformanceSegmentReportResponseData = Record<
  PerformanceSegmentType,
  AdGroupSegmentedPerformanceReport
>

export interface PerformanceSegmentReportResponse {
  data: PerformanceSegmentReportResponseData
}

export interface IndustryListResponse {
  data: IndustryType[]
}

export interface AttributeCategoryType {
  id: number
  name: string
  displayName: string
  industryId: number
}
export interface AttributeType {
  category: AttributeCategoryType
  categoryId: number
  distance: number
  id: number
  name: string
  point: [number, number]
  source: string
  subCategory: string
  thirdPartyId: string
}

export interface KeywordsGenerationStats {
  finished_at: string
  generated: number
  processed: number
  running: boolean
  started_at: string
  unique: number
  wih_volume: number
  with_volume_new: number
}

export interface CheckbookMonthlyAmount {
  Amount: number
  Month: string // date
}

export interface CheckbookAmount {
  data: CheckbookMonthlyAmount[] | null
}

export interface KeywordsGenerationStatsResponse {
  data: KeywordsGenerationStats | null
}
export interface AttributeListResponse {
  data: {
    records: AttributeType[] | null
    total: number
  }
}

export interface GetAdCopyListResponse {
  data: {
    records: AdCopyEntity[]
    total: number
  }
}

export interface GetAdCopyDetailsListResponse {
  data: {
    records: any[]
    total: number
  }
}

export interface GetExtendedAdCopyListResponse {
  data: AdCopyDetailsEntity[] | null
}

export interface CreateAdCopyResponse {
  data: AdCopyInstance
}

export interface GenerateAdCopyWithIAResponse {
  data: {
    headlines: string[]
    descriptions: string[]
  } | null
}

export interface AdGroupReportResponse {
  data: AdGroupReportDataType
}

export type CheckbookUpdateFormRow = {
  month: string
  amount: number
}

export type GetProductFeedListResponse = {
  data: { products?: ProductFeedInstance[]; total?: number }
}

export type GetProductTempChangeResponse = {
  data: ProductTempChangeEntity | null
}

export type CategoriesTreeResponse = {
  data: ProductCategoryNodeData[]
}

export type CategoriesListResponse = {
  data: ProductCategoryOptionData[]
}

export type GetProductFeedStatusResponse = {
  data: ProductFeedStatus
}

export type GetShopifyImportStatusResponse = {
  data: ProductImportStats
}

export type GetShopifySettings = {
  data: ShopifySettingOptions
}

export type GetMetaAdsList = {
  data: MetaAdsItem[]
}

export type GetShopifyStatusUpdateResponse = {
  data: ShopifyStatusUpdate
}

export type PaginatedListRequestParams = {
  limit: number
  offset: number
  sort: string
  order: "ASC" | "DESC"
}

export type GetGMCPublishStatusResponse = {
  data: ProductPublishStats
}

export type GetRelatedTopicsResponse = {
  data: Record<"top" | "rising", TopicData[] | null> | null
}

export type GetRelatedQuestionsResponse = {
  data: {
    questionSet: RelatedQuestionSetName
    questions: RelatedQuestionData[]
  }[]
}

export type GetTopKeywordsResponse = {
  data: TopKeywordEntity[] | null
}

export type GenerateSegmentationConfigResponse = {
  data: {
    SheetUrl: string
    sheetId: string
  }
}

export type MetaAccountsList = {
  data: MetaAccountEntity[]
}

class AccountAPI extends HttpService {
  fetchAccountList = (
    sort: "DESC" | "ASC" = "ASC",
    offset: number = 0,
    limit: number = 99999
  ): Promise<AxiosResponse<AccountResponse>> =>
    this.get(`properties/list`, { sort, limit, offset })

  fetchAccountListByClientId = (
    clientId: number,
    sort: "DESC" | "ASC" = "ASC",
    offset: number = 0,
    limit: number = 99999
  ): Promise<AxiosResponse<AccountResponse>> =>
    this.get(`client/${clientId}/properties`, { sort, limit, offset })

  fetchAccountAttributes = (id: ID): Promise<AxiosResponse<AccountResponse>> =>
    this.get(`property/${id}/keywords/stats`)

  getAccount = (id: ID): Promise<AxiosResponse<AccountDataResponse>> =>
    this.get(`property/${id}`)

  getAccountGoalSettings = (
    id: ID
  ): Promise<AxiosResponse<GetAccountGoalsSettingsResponse>> =>
    this.get(`properties/${id}/goal/settings`)

  getAccountGoalOptions = (): Promise<
    AxiosResponse<GetAccountGoalsOptionsResponse>
  > => this.get(`properties/goal/types`)

  updateAccountGoalSettings = (
    id: ID,
    settings: { targetValue: number; type: string }[]
  ): Promise<AxiosResponse<GetAccountGoalsSettingsResponse>> =>
    this.put(`properties/${id}/goal/settings`, { settings })

  getAccountGoalMonthlyPerformance = (
    accountId: ID
  ): Promise<AxiosResponse<GetAccountGoalsMonthlyPerformanceResponse>> =>
    this.get(`property/${accountId}/performance-monthly-goals`)

  getKeywordsByAccountId = (
    accountId: ID
  ): Promise<AxiosResponse<KeywordsResponse>> =>
    this.get(`property/${accountId}/attributes`, {
      statsOnly: 1,
      monthlyVolume: 1,
      bestKeywords: true,
      limit: 99999,
    })

  getAssignedAttributesByAccountId = (
    accountId: number
  ): Promise<AxiosResponse<AssignedAttributeResponse>> =>
    this.get(`property/${accountId}/attributes`, {
      basic: 1,
    })

  getAttributesByIndustryId = (
    industryId: number
  ): Promise<AxiosResponse<AttributeListResponse>> =>
    this.get(`attributes/list/${industryId}`, {
      limit: 99999,
      basic: 1,
      orderCategories: true,
    })

  getKeywordRelatedTopics = (
    keywordId: ID
  ): Promise<AxiosResponse<GetRelatedTopicsResponse>> =>
    this.get(`attribute/${keywordId}/related-topics`)

  getKeywordTopKeywords = (
    accountId: ID,
    keywordId: ID
  ): Promise<AxiosResponse<GetTopKeywordsResponse>> =>
    this.get(`attribute/${keywordId}/top-keywords`, {
      propertyId: Number(accountId),
    })

  getKeywordRelatedQuestions = (
    accountId: ID,
    keywordId: ID
  ): Promise<AxiosResponse<GetRelatedQuestionsResponse>> =>
    this.get(`attribute/${keywordId}/related-questions`, {
      propertyId: Number(accountId),
    })

  getPerformance = (
    accountId: number,
    filter: { from: string; to: string; compare?: boolean }
  ): Promise<AxiosResponse<PerformanceReportResponse>> =>
    this.get(`property/${accountId}/performance-report`, filter)

  getMonthlySearchReport = (
    accountId: number,
    filter: { from: string; to: string }
  ): Promise<AxiosResponse<GetMonthlySearchReportResponse>> =>
    this.get(`property/${accountId}/search-volume`, filter)

  switchKeyword = (
    accountId: ID,
    filter: { attributeId: number; active: boolean }
  ): Promise<AxiosResponse<PerformanceReportResponse>> =>
    this.put(`property/${accountId}/attribute`, filter)

  getPerformancePeriodReport = (
    accountId: number,
    filter: PerformancePeriodReportFilter
  ): Promise<AxiosResponse<GetPerformancePeriodReportResponse>> =>
    this.get(`property/${accountId}/performance-period-report`, filter)

  getPerformanceSegmentedReport = (
    accountId: number,
    filter: { from: string; to: string; compare: boolean }
  ): Promise<AxiosResponse<PerformanceSegmentReportResponse>> =>
    this.get(`property/${accountId}/performance-segmented-report`, filter)

  getReportDownloadLink = (
    accountId: number,
    { from, to }: { from: Date; to: Date }
  ): string =>
    this.getApiUrl(
      `property/${accountId}/performance-segmented-report/download?from=${apiDateFormatter(
        from
      )}&to=${apiDateFormatter(to)}`
    )

  getIndustriesList = (): Promise<AxiosResponse<IndustryListResponse>> =>
    this.get(`industries/list`)

  updateAccountInfo = (
    accountId: ID,
    data: any
  ): Promise<AxiosResponse<AccountDataResponse>> =>
    this.put(`property/${accountId}`, data)

  updateAttributesAssignment = (
    accountId: number,
    data: any
  ): Promise<AxiosResponse<any>> =>
    this.put(`property/${accountId}/attributes`, data)

  getKeywordsGeneratingStatus = (
    accountId: number
  ): Promise<AxiosResponse<KeywordsGenerationStatsResponse>> =>
    this.get(`property/${accountId}/keywords/stats`)

  startKeywordsGenerating = (accountId: number): Promise<AxiosResponse<any>> =>
    this.post(`background/property/${accountId}/keywords`, {})

  getAccountCheckbook = (
    accountId: number
  ): Promise<AxiosResponse<CheckbookAmount>> =>
    this.get(`property/${accountId}/checkbook`)

  updateAccountCheckbook = (
    accountId: number,
    data: CheckbookUpdateFormRow[]
  ): Promise<AxiosResponse<any>> =>
    this.put(`property/${accountId}/checkbook`, data)

  getLinkAccountLocation = (
    redirectState: string,
    isPublic: boolean = false
  ): Promise<AxiosResponse<any>> =>
    this.get(
      `oauth/connect-account`,
      { state: redirectState },
      isPublic ? "75098363de514e69b4370b56d56e3c96" : true
    )

  getLinkMetaAccount = (
    state: string,
    redirectUrl: string
  ): Promise<AxiosResponse<any>> =>
    this.get(`oauth/meta/connect-account`, { state, redirectUrl })

  getMetaAccountsList = (
    accessToken: string
  ): Promise<AxiosResponse<MetaAccountsList>> =>
    this.get(`oauth/meta/ad-accounts/list`, { accessToken })

  linkMetaAccount = (
    accountId: number,
    metaAccountId: string,
    accountName: string
  ): Promise<AxiosResponse<any>> =>
    this.put(
      `property/${accountId}/meta/link-account`,
      {},
      {
        accountId: metaAccountId,
        accountName,
      }
    )

  getCustomers = (
    code: string,
    state: number | string, // accountId, clientId etc.
    mccOnly: boolean | undefined = undefined,
    isPublic: boolean = false
  ): Promise<AxiosResponse<any>> =>
    this.get(
      `oauth/account-customers`,
      { code, state, mccOnly },
      isPublic ? "75098363de514e69b4370b56d56e3c96" : true
    )

  linkAccount = (
    accountId: number,
    token: string,
    account: string,
    accountName: string
  ): Promise<AxiosResponse<any>> =>
    this.put(
      `property/${accountId}/link-account`,
      {},
      {
        token,
        account,
        accountName,
      }
    )

  validateMetaAccount = (
    accountId: ID,
    metaAccountId: string
  ): Promise<AxiosResponse<DefaultOkResponse>> =>
    this.get(`properties/${accountId}/meta/validate`, {
      adAccountId: metaAccountId,
    })

  getAdGroupsReport = (
    accountId: ID,
    adGroupId: ID,
    params: { from: string; to: string }
  ): Promise<AxiosResponse<AdGroupReportResponse>> =>
    this.get(`property/${accountId}/adgroup/${adGroupId}`, params)

  getAdCopiesList = (
    accountId: ID,
    adGroupIds: number[]
  ): Promise<AxiosResponse<GetAdCopyListResponse>> =>
    this.get(`properties/${accountId}/ad-copies`, {
      adGroupIds,
    })

  getAdCopiesCompareList = (
    accountId: ID,
    adGroupIds: number[]
  ): Promise<AxiosResponse<GetAdCopyListResponse>> =>
    this.get(`properties/${accountId}/ad-copies/compare`, {
      adGroupIds,
    })

  getAdCopiesDetailsByLocalIds = (
    accountId: ID,
    ids: number[]
  ): Promise<AxiosResponse<GetExtendedAdCopyListResponse>> =>
    this.get(`properties/${accountId}/ad-copies/expanded`, {
      ids,
    })

  getAdCopiesDetailsByGoogleIds = (
    accountId: ID,
    googleAdCopyIds: ID[]
  ): Promise<AxiosResponse<GetExtendedAdCopyListResponse>> =>
    this.get(`properties/${accountId}/ad-copies/expanded`, {
      adCopiesIds: googleAdCopyIds.map((id) => Number(id)),
    })

  createAdCopy = (
    accountId: ID,
    data: AdCopyInstance
  ): Promise<AxiosResponse<CreateAdCopyResponse>> =>
    this.post(`properties/${accountId}/ad-copies`, data)

  updateAdCopy = (
    accountId: ID,
    ids: ID[],
    data: Partial<EditAdCopyData>
  ): Promise<AxiosResponse<CreateAdCopyResponse>> =>
    this.put(`properties/${accountId}/ad-copies`, {
      ids: ids.map((id) => Number(id)),
      ...data,
    })

  updateAndPushAdCopy = (
    accountId: ID,
    googleAdCopyIds: ID[],
    data: Partial<EditAdCopyData>
  ): Promise<AxiosResponse<CreateAdCopyResponse>> =>
    this.put(`properties/${accountId}/ad-copies`, {
      adCopiesIds: googleAdCopyIds.map((id) => Number(id)),
      ...data,
    })

  cloneAdCopy = (
    accountId: ID,
    adGroups: { adGroupId: number; campaignId: number }[],
    adCopies: number[]
  ): Promise<AxiosResponse<CreateAdCopyResponse>> =>
    this.put(`properties/${accountId}/ad-copies/clone`, {
      sources: adCopies,
      destinationAdGroups: adGroups,
    })

  removeAdCopies = (
    accountId: ID,
    ids: ID[]
  ): Promise<AxiosResponse<CreateAdCopyResponse>> =>
    this.delete(`properties/${accountId}/ad-copies`, {
      ids: ids.map((id) => Number(id)),
    })

  removeAndPushAdCopies = (
    accountId: ID,
    googleAdCopyIds: ID[]
  ): Promise<AxiosResponse<CreateAdCopyResponse>> =>
    this.delete(`properties/${accountId}/ad-copies`, {
      adCopiesIds: googleAdCopyIds.map((id) => Number(id)),
    })

  restoreAdCopies = (
    accountId: ID,
    adCopies: ID[]
  ): Promise<AxiosResponse<CreateAdCopyResponse>> =>
    this.patch(`properties/${accountId}/ad-copies/undelete`, {
      ids: adCopies.map((id) => Number(id)),
    })

  publishAdCopiesChanges = (accountId: ID): Promise<AxiosResponse<any>> =>
    this.post(`properties/${accountId}/ad-copies/publish`, {})

  resetAdCopiesChanges = (accountId: ID): Promise<AxiosResponse<any>> =>
    this.post(`properties/${accountId}/ad-copies/reset`, {})

  generateAdCopyWithAI = (
    accountId: ID,
    data: AdCopyPrompts
  ): Promise<AxiosResponse<GenerateAdCopyWithIAResponse>> =>
    this.post(`properties/${accountId}/ad-copies/generate`, data)

  rephraseAdCopyWithAI = (
    accountId: ID,
    data: AdCopyPrompts & {
      rephraseHeadlines?: string[]
      rephraseDescriptions?: string[]
    }
  ): Promise<AxiosResponse<GenerateAdCopyWithIAResponse>> =>
    this.post(`properties/${accountId}/ad-copies/rephrase`, data)

  getProductFeedList = (
    accountId: number,
    params: PaginatedListRequestParams | URLSearchParams
  ): Promise<AxiosResponse<GetProductFeedListResponse>> =>
    this.get(`property/${accountId}/products`, params)

  getCategoriesTree = (
    accountId: number
  ): Promise<AxiosResponse<CategoriesTreeResponse>> =>
    this.get(`property/${accountId}/product/category_v2`)

  getCategories = (
    accountId: number,
    query: string
  ): Promise<AxiosResponse<CategoriesListResponse>> =>
    this.get(`property/${accountId}/product/category?q=${query}`)

  getProductChangeSuggestion = (
    accountId: string | number,
    productId: string | number
  ): Promise<AxiosResponse<any>> =>
    this.get(`property/${accountId}/product/${productId}/suggestion`)

  addTemporaryChangeToProduct = (
    accountId: string | number,
    productId: string | number,
    requestData: any
  ): Promise<AxiosResponse<DefaultOkResponse>> =>
    this.post(`property/${accountId}/product/${productId}/temp`, requestData)

  getProductTemporaryChanges = (
    accountId: string | number,
    productId: string | number
  ): Promise<AxiosResponse<GetProductTempChangeResponse>> =>
    this.get(`property/${accountId}/product/${productId}/temp`)

  dropProductTemporaryChanges = (
    accountId: string | number,
    idList: InfiniteIdList
  ): Promise<AxiosResponse<any>> =>
    this.delete(`property/${accountId}/product/temp`, idList)

  dropProductTemporaryChangeById = (
    accountId: string | number,
    productId: string | number
  ): Promise<AxiosResponse<DefaultOkResponse>> =>
    this.delete(`property/${accountId}/product/${productId}/temp`)

  applyProductChange = (
    accountId: string | number,
    productId: string | number,
    requestData: any
  ): Promise<AxiosResponse<DefaultOkResponse>> =>
    this.put(`property/${accountId}/product/${productId}/apply`, requestData)

  importShopifyProducts = (
    accountId: string | number
  ): Promise<AxiosResponse<DefaultOkResponse>> =>
    this.post(`shopify/${accountId}/fetch-product`, {})

  getShopifyImportStatus = (
    accountId: string | number
  ): Promise<AxiosResponse<GetShopifyImportStatusResponse>> =>
    this.get(`shopify/${accountId}/fetch-product/stats`)

  getShopifySettings = (
    accountId: ID
  ): Promise<AxiosResponse<GetShopifySettings>> =>
    this.get(`shopify/${accountId}/settings`)

  getMetaAdsList = (accountId: ID): Promise<AxiosResponse<GetMetaAdsList>> =>
    this.get(`property/${accountId}/meta/ads`)

  setShopifySettings = (
    accountId: ID,
    settings: ShopifySettingOptions
  ): Promise<AxiosResponse<DefaultOkResponse>> =>
    this.put(`shopify/${accountId}/settings`, { ...settings })

  fetchProductFeedStats = (
    accountId: string | number
  ): Promise<AxiosResponse<GetProductFeedStatusResponse>> =>
    this.get(`property/${accountId}/product/feed-stats`)

  fetchProductFeetStatusUpdate = (
    accountId: string | number
  ): Promise<AxiosResponse<GetShopifyStatusUpdateResponse>> =>
    this.get(`property/${accountId}/product/status`)

  getDownloadFeedLink = (accountId: number): string => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    return this.getApiUrl(
      `property/${accountId}/products/download?token=${token}`
    )
  }

  publishProductsToGMC = (
    accountId: ID,
    idList: InfiniteIdList
  ): Promise<AxiosResponse<DefaultOkResponse>> =>
    this.post(`property/${accountId}/product/gmc/publish`, idList)

  getGMCPublishStatus = (
    accountId: ID
  ): Promise<AxiosResponse<GetGMCPublishStatusResponse>> =>
    this.get(`property/${accountId}/product/gmc/publish/stats`)

  publishProductToGMC = (
    accountId: ID,
    productId: ID
  ): Promise<AxiosResponse<DefaultOkResponse>> =>
    this.post(`property/${accountId}/product/gmc/publish/${productId}`, {})

  getLanguagesList = (): Promise<AxiosResponse<any>> => this.get(`languages`)

  generateSegmentationConfigURL = (
    accountId: ID
  ): Promise<AxiosResponse<GenerateSegmentationConfigResponse>> =>
    this.post(`property/${accountId}/segmentation/spreadsheet/generate`, {})

  syncSegmentationConfig = (
    accountId: ID
  ): Promise<AxiosResponse<DefaultOkResponse>> =>
    this.post(`property/${accountId}/segmentation/spreadsheet/sync`, {})
}

export default new AccountAPI({
  API_URL: config.API_ROOT,
})
