import React, { Dispatch, FC, SetStateAction, useState } from "react"
import Box from "@components/ui/Box/Box"
import { Button } from "@components/ui/Button"
import Chip from "@components/ui/Chip/Chip"
import SimpleFiltersMultiselect from "@components/ui/FiltersDropDown/SimpleFiltersMultiselect"
import Icon from "@components/ui/Icon/Icon"
import Typography from "@components/ui/Typography/Typography"
import { metricsDescriptors } from "@framework/constants/metrics"
import useOptionSearchList from "@framework/prototypes/useOptionsSearchList"
import { metricOptions } from "@framework/types/creativeAnalytics"
import { MetricDescription } from "@framework/types/metrics"
import { useStore } from "@store/index"

import styles from "./ColorSorting.module.scss"

const metricsOptionsArray = Object.entries(metricOptions).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
)

interface SortingByColorProps {
  activeMetrics: MetricDescription[]
  setActiveMetrics: Dispatch<SetStateAction<MetricDescription[]>>
}

const SortingByColor: FC<SortingByColorProps> = ({
  activeMetrics,
  setActiveMetrics,
}) => {
  const {
    analyticsFiltersStore: {
      allFilters,
      setFiltersStatus,
      filtersStatus,
      setAllFilters,
    },
  } = useStore()

  const { sortColorMetrics } = allFilters
  const [showColorMetricsSelect, setShowColorMetricsSelect] = useState(false)

  const metricsOptions = useOptionSearchList({
    list: metricsOptionsArray ?? [],
  })

  const handleMetricsOptions = (value: string) => {
    if (filtersStatus === "reportView") {
      setFiltersStatus("reportEditing")
    }
    let colorMetrics = [...allFilters.sortColorMetrics]
    if (colorMetrics.find((it) => it === value)) {
      colorMetrics = colorMetrics.filter((it) => it !== value)
    } else {
      colorMetrics = [...colorMetrics, value]
    }
    setAllFilters({
      ...allFilters,
      sortColorMetrics: [...colorMetrics],
    })

    const newMetric: MetricDescription = metricsDescriptors.find(
      (md) => md.name === value
    ) as MetricDescription
    let filteredMetricsDescriptors = metricsDescriptors.filter((md) =>
      activeMetrics.includes(md)
    )
    if (!filteredMetricsDescriptors.find((it) => it.name === newMetric?.name)) {
      filteredMetricsDescriptors = [...filteredMetricsDescriptors, newMetric]
    }
    setActiveMetrics(filteredMetricsDescriptors)
  }
  const handleRemoveColorMetric = (filter: string) => {
    if (filtersStatus === "reportView") {
      setFiltersStatus("reportEditing")
    }
    const colorMetrics = [...allFilters.sortColorMetrics].filter(
      (it) => it !== filter
    )
    setAllFilters({
      ...allFilters,
      sortColorMetrics: [...colorMetrics],
    })
  }

  return (
    <Box>
      <Typography type="body2" weight="bold">
        Color sorting by
      </Typography>
      <div className={styles.colorMetricsPanel}>
        <Button
          color="primary"
          variant="outlined"
          before={<Icon name="plus" />}
          onClick={() => setShowColorMetricsSelect((prev) => !prev)}
        >
          Add metrics
        </Button>
        {showColorMetricsSelect && (
          <SimpleFiltersMultiselect
            className={styles.colorMetricsSelect}
            withSearch
            inputClassName={styles.colorMetricsSelectInput}
            onSelect={handleMetricsOptions}
            value={sortColorMetrics}
            placeholder="Add metric"
            onClickOutside={() => setShowColorMetricsSelect(false)}
            {...metricsOptions}
          />
        )}
        {sortColorMetrics.map((filter) => {
          if (!filter) return null
          return (
            <Chip
              type="contained"
              color="secondary"
              endIcon={
                <Icon
                  name="cross"
                  onClick={() => handleRemoveColorMetric(filter)}
                />
              }
              className={styles.filterChip}
              key={filter}
            >
              {filter}
            </Chip>
          )
        })}
      </div>
    </Box>
  )
}

export default SortingByColor
