/* eslint-disable no-unused-expressions */
import { ProductImportStats } from "@framework/types/account"
import { action, computed, observable, reaction, toJS } from "mobx"
import accountService from "@services/account.service"
import RootStore from "../RootStore"
import GMCStore from "./gmc-store"
import ProductChangesStore from "./product-changes"
import ProductDashboardStore from "./product-dashboard"
import ProductFilterStore from "./product-filter"
import ProductListStore from "./product-list"
import ProductOptionsStore from "./product-options"
import ProductSettingsStore from "./productSettings"
import ProductMetaAdsStore from "./product-meta-ads"

const PRODUCT_FEED_IMPORT_LUNCHED_KEY = "PRODUCT_FEED_IMPORT_LUNCHED"

export class ProductFeedStore {
  // INJECTIONS
  @observable rootStore: RootStore

  @observable changes: ProductChangesStore

  @observable list: ProductListStore

  @observable options: ProductOptionsStore

  @observable filter: ProductFilterStore

  @observable dashboard: ProductDashboardStore

  @observable settings: ProductSettingsStore

  @observable GMC: GMCStore

  @observable metaAds: ProductMetaAdsStore

  // CONSTRUCTOR

  constructor(root: RootStore) {
    this.rootStore = root
    this.changes = new ProductChangesStore(root)
    this.list = new ProductListStore(root)
    this.options = new ProductOptionsStore(root)
    this.filter = new ProductFilterStore(root)
    this.dashboard = new ProductDashboardStore(root)
    this.GMC = new GMCStore(root)
    this.settings = new ProductSettingsStore()
    this.metaAds = new ProductMetaAdsStore(root)

    this.syncLunchedImports()

    reaction(
      () => this.lunchedImports.size,
      () => {
        localStorage.setItem(
          PRODUCT_FEED_IMPORT_LUNCHED_KEY,
          JSON.stringify(toJS(Array.from(this.lunchedImports)))
        )
      }
    )
  }

  // STATE

  @observable lunchedImports: Set<string> = new Set()

  @observable importStatus: ProductImportStats | null = null

  @computed get isInited() {
    return this.importStatus != null
  }

  @computed get isFeedAvailable() {
    return this.importStatus?.running === "finished"
  }

  @computed get importKey() {
    const userId = this.rootStore.authStore.user?.ID
    const { accountId } = this.rootStore.accountStore
    if (userId == null || accountId == null) return null
    return `${userId}_${accountId}`
  }

  @computed get wasImportLunched() {
    if (this.importKey == null) return false
    return this.lunchedImports.has(this.importKey)
  }

  syncLunchedImports = () => {
    try {
      const str = localStorage.getItem(PRODUCT_FEED_IMPORT_LUNCHED_KEY)
      if (str) this.lunchedImports = new Set(JSON.parse(str))
    } catch (error) {
      this.lunchedImports = new Set()
    }
  }

  @observable isProductsImportLoading: boolean = false

  @observable productsImportError: string | null = null

  @action importShopifyProducts = async (accountId: number) => {
    if (this.isProductsImportLoading) return null
    try {
      if (this.importKey == null) throw new Error()
      this.lunchedImports.add(this.importKey)

      this.productsImportError = null
      this.isProductsImportLoading = true

      this.importStatus = {}
      await accountService.importShopifyProducts(accountId)
      await this.subscribeOnImportStatus(accountId)
    } catch (error) {
      this.productsImportError = "Unexpected error"
    } finally {
      this.isProductsImportLoading = false
    }
    return this.productsImportError
  }

  @action finalizeImport = () => {
    if (this.importKey == null) return
    this.lunchedImports.delete(this.importKey)
  }

  @observable isImportStatusLoading: boolean = false

  @observable importStatusError: string | null = null

  @action loadShopifyImportStatus = async (accountId: number) => {
    try {
      this.isImportStatusLoading = true
      const response = await accountService.getShopifyImportStatus(accountId)
      this.importStatusError = null
      this.importStatus = response.data.data
    } catch (error) {
      this.importStatus = {}
      this.importStatusError = "Unexpected error"
    } finally {
      this.isImportStatusLoading = false
    }
    return this.importStatusError
  }

  @observable private timer?: NodeJS.Timeout

  @action subscribeOnImportStatus = async (
    accountId: number,
    delay: number = 10_000
  ) => {
    this.unsubscribeOnImportStatus()
    this.loadShopifyImportStatus(accountId)

    this.timer = setInterval(() => {
      this.loadShopifyImportStatus(accountId)
    }, delay)
  }

  @action unsubscribeOnImportStatus = () => {
    if (this.timer) clearInterval(this.timer)
  }

  @action reloadData = () => {
    this.list.reloadProductFeed(true)
    this.changes.dropChanges()
  }
}

export default ProductFeedStore
