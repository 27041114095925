import React, { FC, useState } from "react"
import Modal from "@components/ui/Modal/Modal"
import Stack from "@components/ui/Stack/Stack"
import { Button } from "@components/ui/Button"
import Label from "@components/ui/Label/Label"
import TextField from "@components/ui/TextField/TextField"
import { useAlert } from "react-alert"
import Loader from "@components/ui/Loader/Loader"
import styles from "./Modals.module.scss"

interface CreateReportModalProps {
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
  onCreateReport: (name: string) => void
}

const CreateReportModal: FC<CreateReportModalProps> = ({
  onClose,
  isLoading,
  isOpen,
  onCreateReport,
}) => {
  const alert = useAlert()
  const [name, setName] = useState<string>("")

  const handleCreateReport = () => {
    if (!name) {
      alert.error("Please provide a report name")
      return
    }
    onCreateReport(name)
  }
  return (
    <Modal
      title="Create General report"
      isOpen={isOpen}
      onClose={onClose}
      className={styles.modalBase}
    >
      <Stack align="stretch">
        <Stack direction="column" justify="space-between" align="stretch">
          <Label label="Report name">
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={isLoading}
            />
          </Label>
        </Stack>
        {isLoading && <Loader />}
        <Stack direction="row" justify="flex-end">
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleCreateReport}
            disabled={isLoading}
          >
            Create
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}

export default CreateReportModal
